import {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  rectangle23,
  rectangle26_2x,
  next_2,
  MarketingPages,
  ResourcePages,
  chatbot,
  TrainingPages,
} from "../../assets";
import Sidebar from "../../components/Sidebar/Sidebar";
import Announcements from "./Announcements";
import Calendar from "./Calendar";
import Search from "./Search";
import getPages from "../../utils/getPages";
import supabaseConnect from "../../utils/supabaseConnect";
import { title } from "process";
import AnnouncementModal from "../announcement/AnnouncementModal";
import CreateNewFooterMenu from "../../components/CreateNewFooterMenu";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import NewEventModal from "../../components/events/NewEventModal";
import EventModal from "../../components/events/EventModal";

// Todo: add logic to pull data from db as well as functionality

interface page {
  id: number;
  title: string;
  description: string;
  body: string;
  cover_image: string;
  created_at: string;
  tag: string;
  tag_id: number;
}

interface AnnouncementData {
  id: string;
  title: string;
  description: string;
  created_at: string;
  info: string;
  cover_image: string;
}

const Dashboard: FunctionComponent = () => {
  const supabase = supabaseConnect();
  const [pages, setPages] = useState([]) as any[];
  const [users, setUsers] = useState(null) as any;
  const [tags, setTags] = useState([]) as any[];
  let pages1: any[] = [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeAnnouncement, setActiveAnnouncement] = useState<
    AnnouncementData[]
  >([]);
  const [query, setQuery] = useState("");
  const [profile, setProfile] = useState(null) as any[];
  const [isAdmin, setIsAdmin] = useState(false) as any[];
  const [showModal, setShowModal] = useState(false);
  const [list, setList] = useState([]) as any[];
  const [eventData, setEventData] = useState([]) as any[];
  const [activeEvent, setActiveEvent] = useState([]) as any[];
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [beingEdited, setBeingEdited] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const [isWide, setIsWide] = useState(false);

  const checkAdmin = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user?.id;

    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", userId);

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const { data: user, error } = await supabase.auth.getSession();
      if (error) return console.log(error);
      if (!user) {
        return (window.location.href = "/login");
      }
      setUsers(user);
      if (!user.session) {
        return (window.location.href = "/login");
      }
      console.log(users?.session?.user.id);
      return user;
    };
    const getProfile = async (id: string) => {
      const { data: profile, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", id);
      setProfile(profile);
      if (error) return console.log(error);
      setProfile(profile);
      return profile;
    };
    const checkActive = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      const userId = user?.id;

      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", userId);
      if (error) return console.log(error);
      if (data?.[0].active === false || !data?.[0].active) {
        window.location.href = "/inactive";
      }
    };
    const fetchPages = async () => {
      const pages = await getPages();
      setPages(pages);
      fetchTags(pages);
    };
    const fetchTags = async (pages: page[]) => {
      const { data: tags, error } = await supabase.from("tag").select("*");

      if (error) return console.log(error);

      const pagesWithTags = pages.map((page: page) => {
        let pageTag = page.tag_id
          ? tags.find((tag: any) => page.tag_id === tag.id)
          : null;
        return { ...page, tag: pageTag ? pageTag.tag_name : null };
      });

      setPages(pagesWithTags);

      if (!tags) return console.log("no tags found");
    };

    const runGetFunctions = async () => {
      const user = await getUser();
      if (typeof user === "object" && user !== null && user.session) {
        const userId = user.session.user.id;
        await fetchPages();

        await getProfile(userId);
      }
    };
    const getEvents = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .order("id", { ascending: false });
      if (error) console.error("Error fetching events: ", error);
      // order by start_date
      const sortedData = data?.sort((a: any, b: any) => {
        return (
          new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
        );
      });
      // filter out events that have already passed
      const currentDate = new Date();
      let filteredData = sortedData?.filter((event: any) => {
        return new Date(event.start_date) >= currentDate;
      });

      // slice the first 3 events
      filteredData = filteredData?.slice(0, 6);

      // run through the data and shorten the title to 20 characters
      filteredData = filteredData?.map((event: any) => {
        if (event.title.length > 20) {
          event.title = event.title.substring(0, 15) + "...";
        }
        return event;
      });

      setEventData(filteredData);
      return data;
    };
    const runFunctions = async () => {
      await runGetFunctions();
      await checkAdmin();
      await checkActive();
      await getEvents();
    };
    runFunctions();
  }, []);

  const search = async () => {
    try {
      setQuery("");
      return (window.location.href = `search/${query}`);
    } catch (error) {
      console.error("Search failed: ", error);
      return [];
    }
  };

  const handleChatBotClick = () => {
    return () => {
      window.location.href = "/community-chatbot";
    };
  };

  const handleResourcePageClick = () => {
    return () => {
      window.location.href = "/page";
    };
  };

  const handleMarketingPageClick = () => {
    return () => {
      window.location.href = "/marketing";
    };
  };

  const handleTrainingPageClick = () => {
    return () => {
      window.location.href = "/training";
    };
  };

  const handleLoadMore = async () => {
    window.location.href = "/events";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth > 1500);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className=" flex w-screen h-screen overflow-hidden text-left  text-white">
      <Sidebar />
      <NewEventModal
        showModal={showEventModal}
        setShowModal={setShowEventModal}
        setList={setList}
        setEventData={setEventData}
        activeEvent={activeEvent}
      />
      {/* Top banner  */}
      <MainLayoutWrapper>
        <div className="sm:p-5 p-2 flex flex-col max-h-full">
          <div className="relative">
            <img
              className="relative rounded-md w-full sm:h-[17rem] xs:h-[13rem] h-[9rem] -z-10"
              alt="bg-dashbpard"
              src={rectangle23}
            />

            <AnnouncementModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              activeAnnouncment={activeAnnouncement}
            />

            <EventModal
              isOpen={isEventModalOpen}
              onClose={() => setIsEventModalOpen(false)}
              activeEvent={activeEvent}
              setBeingEdited={setBeingEdited}
              setShowEventModal={setShowEventModal}
              setActiveEvent={setActiveEvent}
            />

            <div id="modal-root"></div>
            {/* todo: add logic to pull name of user from db */}

            <div className="absolute top-5 left-5">
              <div className="lg:text-[3rem] md:text-[2.5rem] sm:text-[2rem] xs:text-[1.6rem] text-[1.2rem] text-[1rem] inline-block">
                {profile?.[0].first_name && (
                  <>
                    <p className="m-0">👋 Hello {profile?.[0].first_name}, </p>
                    <p className="m-0">Welcome To Community </p>
                  </>
                )}
              </div>

              <div className="text-base mt-5 max-sm:text-[12px] max-xs:hidden max-sm:mr-3">
                Search through our community database to find the resources or
                tools you’re looking for:
              </div>

              <div className="flex gap-4 items-center w-full">
                <input
                  className="xl:w-[40rem] lg:w-[30rem] md:w-[20rem] sm:w-[15rem] w-full h-[3rem] rounded-md sm:pl-[2rem] pl-[1rem] mt-[0.5rem] max-sm:mr-4 text-base focus:outline-none border-none bg-gray-200"
                  type="text"
                  placeholder="Search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      search();
                    }
                  }}
                />
                <div
                  className="w-fit mt-2 -ml-2 max-sm:hidden"
                  onClick={search}
                >
                  <Search />
                </div>
              </div>
            </div>
          </div>
          {/* search box to type in */}

          <div className="flex flex-wrap m-auto w-full pt-4">

            <div className="flex flex-col xl:w-4/12 lg:w-1/2 w-full md:w-1/2 gap-2 pb-3">

              <div className="flex xs:flex-row flex-col gap-2 md:pr-3">
                <div className="relative xs:w-1/2 w-full">
                  {/* <div className="absolute bg-black opacity-70 z-10 top-0 rounded-t-md rounded-b-none w-full h-full">
                    Coming Soon
                  </div> */}
                  <img
                    className="object-cover w-full md:h-60 h-full cursor-pointer z-20 rounded-t-md"
                    alt="chatbot-img"
                    src={chatbot}
                    onClick={handleChatBotClick()}
                  />
                  <img className="z-2 absolute top-2.5 left-2.5" alt="" src={next_2} />
                  <p className="absolute bottom-1/4 left-2.5 m-0 font-semibold">
                    Community AI Chatbot
                  </p>
                </div>

                <div className="relative xs:w-1/2 w-full">
                  {/* <div
                    className="absolute top-0 rounded-t-md rounded-b-none w-full h-full cursor-pointer z-20"
                    onClick={handleResourcePageClick()}
                  ></div> */}

                  <img
                    className="object-cover w-full md:h-60 h-full cursor-pointer z-20 rounded-t-md"
                    alt=""
                    src={ResourcePages}
                    onClick={handleResourcePageClick()}
                  />
                  <img className="z-10 absolute top-2.5 left-2.5" alt="" src={next_2} />
                  <p className="absolute bottom-1/4 left-2.5 m-0 font-semibold">
                    Resources
                  </p>
                </div>
              </div>

              <div className="flex xs:flex-row flex-col gap-2 md:pr-3">
                <div className="relative xs:w-1/2 w-full">
                  {/* <div
                    className="absolute top-0 rounded-t-md rounded-b-none w-full h-full cursor-pointer z-20"
                    onClick={handleMarketingPageClick()}
                  ></div> */}

                  <img
                    className="object-cover w-full md:h-60 h-full cursor-pointer z-20 rounded-t-md"
                    alt=""
                    src={MarketingPages}
                    onClick={handleMarketingPageClick()}
                  />
                  <img className="z-10 absolute top-2.5 left-2.5" alt="" src={next_2} />
                  <p className="absolute bottom-1/4 left-2.5 m-0 font-semibold">
                    Marketing Center
                  </p>
                </div>

                <div className="relative xs:w-1/2 w-full">
                  {/* <div
                    className="absolute top-0 rounded-t-md rounded-b-none w-full h-full cursor-pointer z-20"
                    onClick={handleTrainingPageClick()}
                  ></div> */}

                  <img
                    className="object-cover w-full md:h-60 h-full cursor-pointer z-20 rounded-t-md"
                    alt=""
                    src={TrainingPages}
                    onClick={handleTrainingPageClick()}
                  />
                  <img className="z-10 absolute top-2.5 left-2.5" alt="" src={next_2} />
                  <p className="absolute bottom-1/4 left-2.5 m-0 font-semibold">
                    Training Resources
                  </p>
                </div>
              </div>

            </div>

            <div className="xl:w-3/12 md:w-1/2 w-full xl:pr-3 pb-3 flex-1" >
              <Announcements
                setIsModalOpen={setIsModalOpen}
                setActiveAnnouncement={setActiveAnnouncement}
                activeAnnouncement={activeAnnouncement}
              />
            </div>

            <div className="xl:w-4/12 w-full sm:mb-5 mb-2 pb-5">
              <div className="border rounded-lg text-base bg-black p-4 h-[29rem] overflow-y-auto pr-[2.5rem]">
                <h2 className="text-white font-thin mb-[2rem]">
                  Community Annual Calendar
                </h2>
                <div className="flex flex-col gap-5">
                  {eventData.slice(0, 3).map((event: any) => (
                    <Calendar
                      eventData={event}
                      setActiveEvent={setActiveEvent}
                      activeEvent={activeEvent}
                      setIsEventModalOpen={setIsEventModalOpen}
                      key={event.id}
                    />
                  ))}
                </div>
                <div
                  onClick={handleLoadMore}
                  className="text-white pl-[1.2rem] text-[.8rem] cursor-pointer pt-[1.9rem]"
                >
                  Load More
                </div>
              </div>
            </div>

          </div>

          {isAdmin && (
            <CreateNewFooterMenu setShowEventModal={setShowEventModal} />
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default Dashboard;
