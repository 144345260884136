import React, {useEffect, useState} from "react";
import { rectangle542x } from "../../assets";
import supabaseConnect from "../../utils/supabaseConnect";

interface UserCardProps {
  pageData: any;
}

const UserCard: React.FC<UserCardProps> = ({
  pageData,
}) => {

    const supabase = supabaseConnect();
    const [userData, setUserData] = useState<any>([]);

    useEffect(() => {
        const getData = async () => {
          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', pageData[0].user_id)
            .limit(1);


          if (error) console.log('error', error);
          else setUserData(data);
        }
        getData();
       }, [])


    

    return (
        <div className="relative rounded-b-md rounded-t-none object-cover p-[1rem]">
              <img
                className="relative rounded-b-md rounded-t-none w-[20rem] object-cover max-h-[30rem] mt-[2rem]"
                alt=""
                src={rectangle542x}
              />

              <div className="absolute top-0 left-0 flex flex-col items-center justify-end w-full max-h-[30rem] pt-[1rem] justify-center mt-[2rem]">
                <h3 className="text-white font-extralight -mb-[.5rem]">
                  Have questions? <br />
                  Page not updated?
                </h3>
                {/* todo: make this dynamic by user: */}
                <h6 className="text-lightGray font-extralight">
                  THIS RESOURCE WAS CREATED BY: <br />
                </h6>

                <img
                  src={userData?.[0]?.profile_image_url || "https://via.placeholder.com/150"}
                  alt="circle img"
                  className="rounded-full w-[10rem]"
                />

                <h3 className="mt-2 text-white font-extralight">{userData?.[0]?.first_name} {userData?.[0]?.last_name} </h3>
                <h4 className="mt-1 font-extralight text-tourquoise">{userData?.[0]?.job_title || "No Title Provided"}</h4>

                {/* todo: Make email dynamic */}
                <a
                  href={`mailto:${userData?.[0]?.email}`}
                  className="mt-1 py-2 px-4 rounded bg-white text-black w-[12rem] flex items-center justify-center no-underline"
                >
                  Email me
                </a>
              </div>
            </div>
    )

};

export default UserCard;