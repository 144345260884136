import React, { useState } from "react";
import ReactDOM from "react-dom";
import supabaseConnect from "../../utils/supabaseConnect";
import { calendar } from "../../assets";
import { pin } from "../../assets";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  activeEvent: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
    start_date: string;
    location: string;
  }[];
  setBeingEdited: (value: boolean) => void;
  setShowEventModal: (value: boolean) => void;
  setActiveEvent: (value: any) => void;
}

const EventModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  activeEvent,
  setBeingEdited,
  setShowEventModal,
  setActiveEvent,
}) => {
  const [userAdmin, setUserAdmin] = useState(false);
  if (!isOpen) return null;
  const date = new Date(activeEvent[0].created_at);
  const supabase = supabaseConnect();

  function convertDateFormat(dateString : any) {
    // Extract components
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-');
    let [hours, minutes] = timePart.split(':');
  
    // Convert hours to 12-hour format
    let period = 'AM';
    if (parseInt(hours) >= 12) {
      period = 'PM';
      if (parseInt(hours) > 12) {
        hours = (parseInt(hours) - 12).toString().padStart(2, '0');
      }
    }
    if (hours === '00') {
      hours = '12';
    }
  
    return `${month}/${day}/${year} | ${hours}:${minutes} ${period}`;
  }


  const dateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero indexed
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) setUserAdmin(true);
  };

  getUserAdmin();

  // convert html string to jsx element
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  const handleEditClick = () => {
    setBeingEdited(true);
    setShowEventModal(true);
    setActiveEvent(activeEvent);
    onClose();
  };

  console.log(activeEvent)

  return ReactDOM.createPortal(
    <>
      <div
        className="fixed inset-0 bg-gray bg-opacity-50 flex items-stretch justify-center z-50"
        onClick={onClose}
      >
        <div
          className="bg-black w-full md:w-3/4 xl:w-3/4 h-full overflow-auto  rounded-lg mx-auto flex flex-col text-white"
          onClick={(e) => e.stopPropagation()} // Prevents click from closing the modal
        >
          <div className="w-[full] h-full text-left  text-white font-inter">
            <div className="relative w-full h-[20rem] text-xs text-gray ">
              <div className="w-full relative">
                {/* <img
              className="relative top-0 rounded-t-md rounded-b-none h-[15rem] w-full object-cover"
              alt=""
              src="https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703"
            /> */}
                <div className="w-full absolute bg-black/70 "></div>
              </div>
              

              {activeEvent.map((event) => (
                <React.Fragment key={event.id}>
                  {userAdmin && (
                    <div className="p-[1rem]">
                      <button
                        onClick={() => handleEditClick()}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer  max-sm:w-full"
                      >
                        Edit
                      </button>
                    </div>
                  )}
                  <div className="relative flex items-center justify-center flex-col py-[50px] px-2">
                    <div className="text-white font-semibold text-3xl sm:text-5xl tracking-[0.06em] leading-[150%]  text-center w-full">
                      {event.title} <br />
                    </div>
                    <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full ">
                      {event.description} <br />
                    </div>
                    <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full">
                      {/* todo: logic from db for the date */}
                    </div>
                      <div className="gradient-overlay"></div>
                  </div>
                  <div className="flex sm:flex-row flex-col sm:gap-20 gap-4 p-[50px]">
                      <div>
                        <h3 className="mt-0 text-[24px] font-bold text-white mb-2">When?</h3>
                        <p className="text-[17px] text-white min-w-[20rem]">{`${convertDateFormat(event.start_date)}`}</p>{" "}
                        {/* Replace with event's date */}
                      </div>
                      <div>
                        <h3 className="mt-0 text-[24px] font-bold text-white mb-2">Where?</h3>
                        <p className="text-[17px] text-white ">
                          {event.location}
                        </p>{" "}
                        {/* Replace with event's location */}
                      </div>
                    </div>

                  <div className="">
                    <div className="px-[50px]">
                      {/* Main content */}
                      <h3 className="mt-0 text-[24px] font-bold text-white mb-2">About this event:</h3>

                      <div className="text-white rounded-lg  flex justify-start relative pb-2">
                        {/* Adding a sample section, output your actual content dynamically */}
                        <section className="mb-8">
                          <div
                            className="w-full"
                            dangerouslySetInnerHTML={createMarkup(event.info)}
                          />
                          {/* Add more content here - could be a list of resources, articles, or other relevant content */}
                        </section>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default EventModal;
