import React, { useState } from "react";
import {
  bottomCornerLoginPage,
  communityNameLogo,
  topCornerLoginPage,
  backGroundLinesLoginPage2,
} from "../../assets/index";
import supabaseConnect from "../../utils/supabaseConnect";
import { redirect } from "react-router-dom";

const ConfrimEmailForPasswordChange = () => {
  // const errors:any = [];
  const [errors, setErrors] = useState<any>([]);
  // build supabase login functionality here
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // prevent the default form submission behavior
    const formData = new FormData(event.currentTarget); // get the form data
    const email = formData.get("email") as string; // get the email value
    const password = formData.get("password") as string; // get the password value
    const supabase = supabaseConnect();

  };


  return (
    <body className="h-screen bg-gradient-to-tl from-gray to-black z-[-10] ">
      <div className="flex flex-col items-center justify-center h-screen sm:p-0 px-7">
        <img
          className="relative mr-[30rem] -mb-[1rem] w-full max-w-[3rem] sm:block hidden"
          src={topCornerLoginPage}
          alt="bottom corner login page"
        />
        <div
          className={`flex flex-col items-center justify-center sm:w-[30rem] w-full  
min-h-[${28 + errors.length}rem] bg-white rounded-sm shadow-2xl sm:p-0 px-5 `}
        >
          <img
            className="flex -mt-[10rem] pb-[2rem] w-full max-w-[20rem]"
            src={communityNameLogo}
            alt="community name logo"
          />
          <span className="text-[.7rem] font-Poppins mt-[6rem] text-darkBlue">
            Welcome!
          </span>
            <h1 className="text-[1.2rem] font-bold mt-[1rem] font-Poppins">
                An Email has been sent to change your password
            </h1>
            <p className="text-[1rem] font-Poppins mt-[1rem] text-darkBlue p-[2rem]">
                If you dont see an email check your spam folder.
            </p>
        </div>
        <img
          className="relative -mt-[1rem] ml-[30rem] w-full max-w-[3rem] sm:block hidden"
          src={bottomCornerLoginPage}
          alt="bottom corner login page"
        />
      </div>
      <img
        className="absolute bottom-0 w-full z-[1]"
        src={backGroundLinesLoginPage2}
        alt="background lines"
      />
    </body>
  );
};

export default ConfrimEmailForPasswordChange;
