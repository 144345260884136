import React, {useState} from 'react';
import {
  bottomCornerLoginPage,
  communityNameLogo,
  topCornerLoginPage,
  backGroundLinesLoginPage2
} from '../../assets/index';
import supabaseConnect from '../../utils/supabaseConnect';
import { redirect, Link  } from 'react-router-dom';


const Login = () => {
  // const errors:any = [];
  const [errors, setErrors] = useState<any>([]);
  const [email, setEmail] = useState<string>('');
  const [showPasswordChange, setShowPasswordChange] = useState<boolean>(false);
  const supabase = supabaseConnect();
  // build supabase login functionality here
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // prevent the default form submission behavior
    const formData = new FormData(event.currentTarget); // get the form data
    const email = formData.get('email') as string; // get the email value
    const password = formData.get('password') as string; // get the password value
    const supabase = supabaseConnect();


    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        setErrors([error]);
        throw error;
      } else {
        console.log(data);
        window.location.href = '/'
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleForgotPassword = async () => {
    return window.location.href = '/password-change-request';
  };

  const handlePasswordReset = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email);
      if (error) {
        setErrors([error]);
      } else {
        console.log('Password reset email sent');
        setShowPasswordChange(false);
      }
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <body className="h-screen bg-gradient-to-tl from-gray to-black z-[-10] ">
      <div className="flex flex-col items-center justify-center h-screen">
        <img className="relative mr-[30rem] -mb-[1rem] w-full max-w-[3rem] sm:block hidden" src={topCornerLoginPage} alt="top corner login page" />
        <div className={`flex flex-col items-center justify-center sm:w-[30rem] w-full  
min-h-[${28 + errors.length}rem] bg-white rounded-sm shadow-2xl sm:p-1 px-5 `}>
          <img className="flex  -mt-[5rem] sm:-mt-[10rem] pb-[2rem] w-full max-w-[20rem]" src={communityNameLogo} alt="community name logo" />
          <span className="text-[.7rem] font-Poppins sm:mt-[6rem] mt-[2.5rem] text-darkBlue">Welcome back!</span>
          <h1 className="text-[1.2rem] font-bold mt-[1rem] font-Poppins">Login to your account</h1>
          {errors.length > 0 && (
            <div className="relative w-full top-0 bg-red-300 ml-center mt-[1.5rem] text-red-500 border-solid border-[1px]">
              {errors.map((error: any, i: any) => (
                <p key={i} className='pl-[1rem]' >{error.message}</p>
              ))}
            </div>
          )}
           <form
      className="flex flex-col items-center justify-center w-10/12 sm:w-80 h-80 z-[10]"
      onSubmit={handleSubmit}
    >
      <div className="flex w-full -mt-[2rem]">
        <label className="text-sm">Email Address</label>
      </div>
      <input
        name="email"
        className="w-full h-10 mt-1 mb-[1rem] px-2 focus:outline-none border-solid border-[.2px] border-lightGray rounded-md shadow"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!showPasswordChange && (
        <>
          <div className="flex flex-col items-start justify-start w-full">
            <label className="text-sm">Password</label>
          </div>
          <input
            name="password"
            className="w-full h-10 pl-2 mt-1 border-solid border-[.2px] border-lightGray rounded-md shadow"
            type="password"
          />
          <Link to="#" className="text-lightBlue underline mb-4" onClick={handleForgotPassword}>
            Forgot Password
          </Link>
        </>
      )}
      {showPasswordChange && (
        <>
          <div className="flex flex-col items-start justify-start w-full">
            <label className="text-sm">New Password</label>
          </div>
          <input
            className="w-full h-10 pl-2 mt-1 border-solid border-[.2px] border-lightGray rounded-md shadow"
            type="password"
          />
          <button
            className="w-full h-10 sm:mt-11 mt-14 text-white border-none bg-lightBlue rounded-md cursor-pointer"
            onClick={handlePasswordReset}
          >
            Reset Password
          </button>
        </>
      )}
      {!showPasswordChange && (
        <button
          className="w-full h-10 sm:mt-11 mt-14 text-white border-none bg-lightBlue rounded-md cursor-pointer"
          type="submit"
        >
          Sign In
        </button>
      )}
    </form>
          
        </div>
        <img className="relative -mt-[1rem] ml-[30rem] w-full max-w-[3rem] sm:block hidden 5xl:-mt-[2rem]" src={bottomCornerLoginPage} alt="bottom corner login page" />
      </div>
      <img className="absolute bottom-0 w-full z-[1]" src={backGroundLinesLoginPage2} alt="background lines" />
    </body>
  )
}

export default Login;