import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { magnifyingglass, rectangle, communityLogo1 } from "../../assets";
import CardGrid from "../resourcesPage/ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";

const TrainingHomePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>([
    {
      id: 4,
      title: "REAC",
      description: "Real Estate Accelerator Community",
      body: "Real Estate Accelerator Community",
      tag: "Training",
      cover_image: communityLogo1,
      tag_id: 1,
    },
    {
      id: 5,
      title: "Community Agent Coaching",
      description: "Link to schedule a coaching session with a Matt McKenzie",
      body: "Link to schedule a coaching session with a Matt McKenzie",
      tag: "Training",
      cover_image: communityLogo1,
      tag_id: 1,
    },
  ]);
  const [activePageData, setActivePageData] = useState<any>([]);
  const supabase = supabaseConnect();

  const handleInput = () => {
    if (divRef.current) {
      setSearchTerm(divRef.current.innerText);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await supabase
        .from("page")
        .select("*")
        .eq("tag", "Training")
        .limit(20);
      if (error) console.log("error", error);
      else setPageData(data);
    };
    // getData();
  }, []);

  const addAgentAttractionResources = () => {
    const newResources = [
      {
        id: 8,
        title: "Summary Video about Community",
        description: "Summary Video about Community",
        body: "Summary Video about Community",
        tag: "Resource",
        cover_image:
          "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: 1,
        link: "https://www.loom.com/share/c26237c412194f9db272f0a92e4543b4?sid=799d19b6-9bac-44b7-b5ab-28479ea1ab98",
      },
      {
        id: 9,
        title: "Slide Deck about Community",
        description: "Slide Deck about Community",
        body: "Slide Deck about Community",
        tag: "Resource",
        cover_image:
          "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: 1,
        link: "https://drive.google.com/file/d/1s8z18THWEKW2gndSGeeVMMzo4HJ7nc6E/view",
      },
      {
        id: 10,
        title: "Summary video about REAL",
        description: "Summary video about REAL",
        body: "Summary video about REAL",
        tag: "Resource",
        cover_image:
          "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: 1,
        link: "https://www.loom.com/share/c3872836a9f84cc8a7313be7108f3e3e?sid=4231a4d3-37e5-471f-9fd4-541cbd119e9a",
      },
      {
        id: 11,
        title: "Slide Deck about REAL",
        description: "Slide Deck about REAL",
        body: "Slide Deck about REAL",
        tag: "Resource",
        cover_image:
          "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: 1,
        link: "https://drive.google.com/file/d/1Lrr-uuvn0d-ZIh4tBhxxPbdUsVhpMtbB/view",
      },
      {
        id: 12,
        title: "Website for agents",
        description: "Signing up for Hello Day Orientation",
        body: "Website for agents",
        tag: "Resource",
        cover_image:
          "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: 1,
        link: "https://www.realcommunity.com/demo",
      },
    ];

    setPageData((prevData: typeof pageData) => [...newResources]);
  };

  const search = async () => {
    try {
      // Execute search queries for each table
      setLoading(true);
      const titleResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("title", searchTerm, { type: "websearch" }),
      ]);
      const descriptionResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("body", searchTerm, { type: "websearch" }),
      ]);

      console.log(
        "Search results: ",
        titleResults,
        descriptionResults,
        bodyResults
      );

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(descriptionResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);
      setPageData(results);
      setLoading(false);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  // todo: add functionality to the cardGrid and make them pull from db instead of the array above

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3  flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Training Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Trainings"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          <CardGrid
            data={pageData}
            onAgentAttractionClick={addAgentAttractionResources}
          />
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default TrainingHomePage;
