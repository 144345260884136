import { createClient } from "@supabase/supabase-js";




const supabaseConnect = () => {

const supabaseURL = process.env.REACT_APP_DB_URL || "";
const supabaseKey = process.env.REACT_APP_ANON_KEY || "";


let supabase = createClient(supabaseURL, supabaseKey);

if (!supabase) {
  throw new Error("Could not connect to Supabase");
}

  return supabase;
}

export default supabaseConnect;
