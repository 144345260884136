import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import CardGrid from "../resourcesPage/ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import LoadingSpinner from "../../components/LoadingSpinner";
import { communityLogo1 } from "../../assets";

const MarketingPageHome = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>([]);
  const [activePageData, setActivePageData] = useState<any>([]);
  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data: linkData, error: linkDataError } = await supabase
        .from('link')
        .select('*')
        .eq('tag', 'Marketing');

      if (linkDataError) console.log("error", linkDataError);

      let marketingItems = linkData?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image || communityLogo1,
        tag_id: item.tag_id,
        link: item.link,
        parent_directory: item.parent_directory,
      })) ?? [];

      // Organize marketing items into a hierarchical structure
      const organizedMarketingItems = marketingItems.reduce((acc: any, item: any) => {
        if (!item.parent_directory || item.parent_directory === "Marketing_Home_Page") {
          acc.push(item);
        } else {
          const parentIndex = acc.findIndex((resource: any) => resource.title === item.parent_directory);
          if (parentIndex !== -1) {
            if (!acc[parentIndex].children) {
              acc[parentIndex].children = [];
            }
            acc[parentIndex].children.push(item);
          } else {
            acc.push({
              id: `parent_${item.parent_directory}`,
              title: item.parent_directory,
              description: `Marketing resources for ${item.parent_directory}`,
              tag: "Marketing",
              cover_image: communityLogo1,
              children: [item]
            });
          }
        }
        return acc;
      }, []);

      setPageData(organizedMarketingItems);
      setLoading(false);
    };

    fetchData();
  }, []);

  const search = async () => {
    try {
      setLoading(true);
      const { data: searchResults, error } = await supabase
        .from("link")
        .select("*")
        .eq("tag", "Marketing")
        .or(`name.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);

      if (error) throw error;

      let results = searchResults?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image || communityLogo1,
        tag_id: item.tag_id,
        link: item.link,
        parent_directory: item.parent_directory,
      })) ?? [];

      setPageData(results);
    } catch (error) {
      console.error("Search failed: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (item: any) => {
    if (item.children) {
      setActivePageData(item.children);
    } else {
      // Handle opening the link or navigating to the resource
      window.open(item.link, "_blank");
    }
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Marketing Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Marketing"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                {loading ? <LoadingSpinner /> : <Search />}
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <CardGrid
                data={activePageData.length > 0 ? activePageData : pageData}
                onCardClick={handleCardClick}
              />
              {pageData.length === 0 && searchTerm && (
                <div className="mt-10 ml-4 text-center">
                  No marketing results found for "{searchTerm}"
                </div>
              )}
            </>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default MarketingPageHome;
