import React from 'react';
import { magnifyingglass } from "../assets";

const LoadingSpinner: React.FC = () => {
  return (
    <div className="bg-gainsboro bg-[#1d2025] px-4 w-fit rounded-lg cursor-wait" role="status">
      <div className="flex gap-4 items-center">
        <p className="text-white">Loading</p>
        <img
          className="animate-spin"
          alt=""
          src={magnifyingglass}
        />
      </div>
    </div>
  );
};

export default LoadingSpinner;