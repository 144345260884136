import './App.css';
import supabaseConnect from './utils/supabaseConnect';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import Dashboard from './pages/dashboard';
import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import SearchResults from './pages/search/SearchResults';
import ResourcesPageHome from './pages/resourcesPage/ResourcesPageHome';
import ResourcesPage from './pages/resourcesPage/ResourcesPage';
import AdminDashboard from './pages/admin/panel/index';
import AddPage from './pages/admin/page';
import AddAnnouncement from './pages/admin/announcements';
import Calendar from './components/calendar'
import Announcement from './pages/announcement';
import Profile from './pages/profile/';
import MarketingPageHome from './pages/marketing';
import TrainingHomePage from './pages/training';
import EditPage from './pages/admin/page/editPage';
import EditAnnouncement from './pages/admin/announcements/editAnnouncement';
import Users from './pages/admin/users';
import EditUsers from './pages/admin/users/editUsers';
import CalendarV1 from './pages/calendarv1/index'
import Inactive from './pages/inactive';
import ChangePassowrd from './pages/auth/ChangePassword';
import RequestPasswordChange from './pages/auth/RequestPasswordChange';
import ConfrimEmailForPasswordChange from './pages/auth/EmailSentConfirmation';
import ChatBotPage from './pages/chatbot';


function App() {
  const supabase = supabaseConnect();
  if (supabase) {
    // console.log('supabase connected', supabase);
  }
  return (
    
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/search/:query" element={<SearchResults />} />
        <Route path="/search" element={<SearchResults />} />
        <Route path="/page" element={<ResourcesPageHome />} />
        <Route path="/page/:id" element={<ResourcesPage />} />
        <Route path="/admin" element={<AdminDashboard />} />
        <Route path="/admin/addpage" element={<AddPage />} />
        <Route path="/admin/addannouncement" element={<AddAnnouncement />} />
        {/* <Route path="/events" element={<Calendar />} /> */}
        <Route path="/announcements" element={<Announcement />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/marketing" element={<MarketingPageHome />} />
        <Route path="/training" element={<TrainingHomePage />} />
        <Route path="/admin/pages/edit/:id" element={<EditPage />} />
        <Route path="/admin/announcements/edit/:id" element={<EditAnnouncement />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/edit/:id" element={<EditUsers />} />
        <Route path="/events" element={<CalendarV1 />} />
        <Route path="/inactive" element={<Inactive />} />
        <Route path="/update-password" element={<ChangePassowrd />} />
        <Route path="/password-change-request" element={<RequestPasswordChange />} />
        <Route path="/email-sent-confirmation" element={<ConfrimEmailForPasswordChange />} />
        <Route path="/community-chatbot" element={<ChatBotPage />} />
        
      </Routes>
  );
}

export default App;
