import React, { useState } from "react";
import ReactDOM from "react-dom";
import supabaseConnect from "../../utils/supabaseConnect";
import "./AnnouncementCSS.css" 

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  activeAnnouncment: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
  }[];
}

const AnnouncementModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  activeAnnouncment,
}) => {
  const [userAdmin, setUserAdmin] = useState(false);
  if (!isOpen) return null;
  const date = new Date(activeAnnouncment[0].created_at);
  const supabase = supabaseConnect();

  const dateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // months are zero indexed
    const day = date.getDate();
    return `${month}/${day}/${year}`;
  };

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) setUserAdmin(true);
  };

  getUserAdmin();

  console.log(activeAnnouncment, "activeAnnouncment")

  // convert html string to jsx element
  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  const handleEditClick = () => {
    window.location.href = `/admin/announcements/edit/${activeAnnouncment[0].id}`;
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-gray bg-opacity-50 flex items-stretch justify-center z-50"
      onClick={onClose}
    >
      <div
        className="bg-black w-full md:w-3/4 xl:w-1/2 h-full overflow-auto p-6 rounded-lg flex flex-col items-center text-white relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="close-button"
          aria-label="Close announcement"
        >
          &times;
        </button>
        {activeAnnouncment.map((announcement) => (
          <>
            <div className="w-full flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold mb-1 text-center flex-grow" key={announcement.id}>
                {announcement.title}
              </h2>
              {userAdmin && (
                <button
                  onClick={handleEditClick}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer"
                >
                  Edit
                </button>
              )}
            </div>
            <div className="w-full text-center text-gray mb-4 mt-4">
              {announcement.description}
            </div>
            <p className="text-gray mb-4">Published on: {dateToString(date)}</p>
            <hr className="mb-4 w-full" />
            {announcement.cover_image.length > 2 && (
              <img
                className="w-full h-[17rem] object-cover"
                alt=""
                src={announcement.cover_image}
              />
            )}
            <div
              className="w-full content-container"
              dangerouslySetInnerHTML={createMarkup(announcement.info)}
            />
          </>
        ))}
      </div>
    </div>,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default AnnouncementModal;