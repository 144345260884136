import React, {useState} from 'react';
import {
  bottomCornerLoginPage,
  communityNameLogo,
  topCornerLoginPage,
  backGroundLinesLoginPage2
} from '../../assets/index';
import supabaseConnect from '../../utils/supabaseConnect';
import { redirect } from 'react-router-dom';


const ChangePassowrd = () => {
  // const errors:any = [];
  const [errors, setErrors] = useState<any>([]);
  // build supabase login functionality here
   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    // Validate form inputs
    const validationErrors = [];

    if (!email) {
      validationErrors.push({ message: 'Email is required' });
    }

    if (!password) {
      validationErrors.push({ message: 'Password is required' });
    }

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    const supabase = supabaseConnect();
    try {
      await supabase.auth.updateUser({ email: email, password: password });
      window.location.href = '/login';
    } catch (error) {
      console.log(error);
      setErrors([{ message: 'An error occurred. Please try again.' }]);
    }
  }


  return (
    <body className="h-screen bg-gradient-to-tl from-gray to-black z-[-10] ">
      <div className="flex flex-col items-center justify-center h-screen sm:p-0 px-7">
        <img className="relative mr-[30rem] -mb-[1rem] w-full max-w-[3rem] sm:block hidden" src={topCornerLoginPage} alt="bottom corner login page" />
        <div className={`flex flex-col items-center justify-center sm:w-[30rem] w-full  
min-h-[${28 + errors.length}rem] bg-white rounded-sm shadow-2xl sm:p-0 px-5 `}>
          <img className="flex -mt-[10rem] pb-[2rem] w-full max-w-[20rem]" src={communityNameLogo} alt="community name logo" />
          <span className="text-[.7rem] font-Poppins mt-[6rem] text-darkBlue">Welcome!</span>
          <h1 className="text-[1.2rem] font-bold mt-[1rem] font-Poppins">Change Your Password</h1>
          {errors.length > 0 && (
            <div className="relative w-full top-0 bg-red-300 ml-center mt-[1.5rem] text-red-500 border-solid border-[1px]">
              {errors.map((error: any, i: any) => (
                <p key={i} className='pl-[1rem]' >{error.message}</p>
              ))}
            </div>
          )}
          <form className="flex flex-col items-center justify-center sm:w-80 w-full   h-80 z-[10] pl-[1rem] pr-[1rem]" onSubmit={(e) => handleSubmit(e)}>
            <div className="flex w-full -mt-[2rem]">
              <label className="text-sm">Email</label>
            </div>
            <input name='email' className="w-full h-10 mt-1 mb-[1rem] focus:outline-none border-solid border-[.2px] border-lightGray rounded-md shadow " type="text" />
            <div className="flex flex-col items-start justify-start w-full">
              <label className="text-sm">New Password</label>
            </div>
            <input name='password' className="w-full h-10 mt-1 focus:outline-none border-solid border-[.2px] border-lightGray rounded-md shadow" type="text" />
            <button className="w-full h-10 mt-7 text-white border-none bg-lightBlue rounded-md cursor-pointer" type="submit">Submit</button>
          </form>
          
        </div>
        <img className="relative -mt-[1rem] ml-[30rem] w-full max-w-[3rem] sm:block hidden" src={bottomCornerLoginPage} alt="bottom corner login page" />
      </div>
      <img className="absolute bottom-0 w-full z-[1]" src={backGroundLinesLoginPage2} alt="background lines" />
    </body>
  )
}

export default ChangePassowrd;
