// @ts-nocheck

import React, { useEffect, useRef, useState} from 'react';
import { Editor } from '@tinymce/tinymce-react';


 export default function App({
    editor,
    setEditor,
    body,
 }) {
   const editorRef = useRef(null);
   const [content, setContent] = useState(editor)
   const log = () => {
     if (editorRef.current) {
       console.log(editorRef.current.getContent());
       setEditor(editorRef.current.getContent());
     }
   };
  const initialContentSet = useRef(false); // Track whether content has been initially set

  const handleEditorChange = (newContent) => {
    
    console.log('Content was updated:', newContent)
    setEditor(newContent);
  };


   return (
     <>
       <div id="editor">
            <Editor
              apiKey="7vt3kpct0014sdw2chns3mqo7oqj588dlmi3ak5vs284pwsj"
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={editor}
              // onEditorChange={(newContent) => handleEditorChange(newContent)}
              onBlur={(e) => handleEditorChange(e.target.getContent())}
              // onBeforeAddUndo={handleBeforeAddUndo}
              init={{
                selector: 'input',
                content_style: "body {color:#F5F5F5; overflow-x: hidden; overflow-y: scroll; padding-top:2rem; z-index: -1; width: 100%;}",
                skin_url: "/skins/ui/CUSTOM",
                placeholder: "Start typing here...",
                resize: false,
                statusbar: false,
                content_css: "/skins/content/CUSTOM",
                skin: "CUSTOM",
                menubar: false,
                resize: false,
                // toolbar: false,
                plugins: 
                  "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount",
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help | image",
                  image_uploadtab: false,
                  contextmenu: "undo redo | formatselect | " +
                  "bold italic backcolor | image ",
              }}
              toolbar_mode="wrap"
              
            />
          </div>
     </>
   );
 }