import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { rectangle232xredOranage, rectangle542x } from "../../assets";
import { useParams } from "react-router-dom";
import supabaseConnect from "../../utils/supabaseConnect";
import UserCard from "../../components/user/UserCard";

const ResourcesPage = () => {
  const { id } = useParams<{ id: string }>();
  const [pageData, setPageData] = useState<any>([]);
  const supabase = supabaseConnect();
  const [userAdmin, setUserAdmin] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await supabase
        .from("page")
        .select("*")
        .eq("id", id)
        .limit(20);

        console.log(data)
      if (error) console.log("error", error);
      else setPageData(data);
    };
    const getUserAdmin = async () => {
      const user = await supabase.auth.getSession();
      const { data, error } = await supabase
        .from("profile_role")
        .select("*")
        .eq("user_id", user.data.session?.user.id)
        .limit(1);
      if (error) console.log("error", error);
      if (data?.[0].role_id === 1) setUserAdmin(true);
    };

    getUserAdmin();
    getData();
  }, []);

  if (!pageData.length) return null;
  const page = pageData[0];
  const { title, description, cover_image, body, created_at, tag } = page;

  const createMarkup = (htmlString: string) => {
    return { __html: htmlString };
  };

  // todo: clean up h on black bg to not to all the way off screen

  const handleEditClick = () => {
    window.location.href = `/admin/pages/edit/${id}`;
  }

  return (
    <>
      <div className="w-[full] h-full text-left text-5xl text-white font-inter pr-[20rem]">
        <div className="fixed top-0 left-0 z-10 h-full">
          <Sidebar />
        </div>
        <div className="ml-[18rem] relative top-[1rem] w-full h-[20rem] text-xs text-gray p-[1rem]  ">
       <div className="w-full h-full relative">
       <img
            className="relative top-0 rounded-t-md rounded-b-none h-[15rem] w-full object-cover"
            alt=""
            src={pageData[0].cover_image}
          />
          <div className="w-full h-full absolute bg-black/70 top-0"></div>
       </div>
          {userAdmin && (
          <div className="absolute top-5 right-5 z-50 p-[1rem]">
            <button onClick={handleEditClick} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer ">
              Edit
            </button>
          </div>
          )}
          <div className="absolute top-1/2 left-1/2 -mt-[7rem] transform -translate-x-1/2 flex items-center justify-center flex-col">
            <div className="text-sm font-bold bg-white text-gray-900 tracking-[0.06em] leading-[150%] text-center w-[7rem] mb-[1rem]">
              {tag.toUpperCase()} <br />
            </div>
            <div className="text-white font-semibold text-[3rem] tracking-[0.06em] leading-[150%] mb-[.8rem] text-center w-full">
              {title} <br />
            </div>
            <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full mb-[.5rem]">
              {description} <br />
            </div>
            <div className="text-white text-sm tracking-[0.06em] leading-[150%] text-center w-full">
              {/* todo: logic from db for the date */}
              <span className="text-3xs opacity-[0.5]">
                Published on: {new Date(created_at).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="flex justify-end relative w-full bg-black -mt-[1rem] h-auto">
            <div className="flex relative">
              {/* Main content */}
              <div className="text-white rounded-lg p-8 flex justify-start relative pb-2">
                {/* Adding a sample section, output your actual content dynamically */}
                <section className="mb-8">
                  <div
                    className="w-full"
                    dangerouslySetInnerHTML={createMarkup(body)}
                  />
                  {/* Add more content here - could be a list of resources, articles, or other relevant content */}
                </section>
              </div>
            </div>
            <UserCard pageData={pageData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourcesPage;
