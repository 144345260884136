import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";

interface CardProps {
  cover_image: string;
  title: string;
  description: string;
  id: number;
  tag_id: number;
  tag: string;
  docId: string;
  tag_name: string;
  link: string;
  onAgentAttractionClick: () => void;
}

// const submit = (id: number) => {
//   console.log(id);
//   return window.location.href = `/page/${id}`;

// }

const submit = (
  id: number,
  docId: string,
  link: string,
  onAgentAttractionClick: () => void
) => {
  // open window in new tab to whole new website
  // create a switch case to open different websites based on id
  console.log(id, docId);
  switch (id) {
    case 1:
      return window.open(
        `https://drive.google.com/drive/u/1/folders/1i5l_2L2T5pm5rIyZpljc4puS4usI6gDB`,
        "_blank"
      );
    case 2:
      return window.open(
        `https://drive.google.com/drive/u/1/folders/1gx9RGi9ZP0el7QBl4wF9uwC2nbegn5qg`,
        "_blank"
      );
    case 3:
      return window.open(
        `https://drive.google.com/drive/u/1/folders/1ktiatuVjk4KnNZJEqWCSB-vmNULGnKL8`,
        "_blank"
      );
    case 4:
      return window.open(`https://portal.realcommunity.com/courses/library-v2`, "_blank");
    case 5:
      return window.open(
        `https://calendly.com/connectwithmattm/community-agent-coaching`,
        "_blank"
      );
    case 7:
      onAgentAttractionClick();
      break;
    default:
      return window.open(link, "_blank");
  }
};

const Card: React.FC<CardProps> = ({
  cover_image,
  title,
  description,
  id,
  tag,
  docId,
  link,
  onAgentAttractionClick,
}) => {
  const [tagName, setTagName] = useState(null) as any[];

  // make description shorter if it's too long
  if (description.length > 50) {
    description = description.substring(0, 50) + "...";
  }

  const getTag = async (tag_name: string) => {
    const supabase = supabaseConnect();
    const { data: tag, error } = await supabase
      .from("tag")
      .select("*")
      .eq("tag_name", tag_name);

    if (error) return console.log(error);
    setTagName("Resource");
  };

  useEffect(() => {
    getTag(tag);
  }, []);

  return (
    <div
      onClick={() => submit(id, docId, link, onAgentAttractionClick)}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[18rem] bg-black cursor-pointer"
    >
      <div className="flex ">
        <img
          className="h-24 w-full object-cover"
          src={communityLogo1}
          alt=""
        />
      </div>
      <div className="flex-1 bg-black p-3 flex flex-col justify-between">
        <div className="flex-1 text-[1rem]">
          <p className="text-sm font-medium text-blue-600 -mt-[.4rem] -mb-[.8rem]">
            <div
              onClick={() => submit(id, docId, link, onAgentAttractionClick)}
              className="no-underline text-tourquoise"
            >
              {tagName}
            </div>
          </p>
          <div
            onClick={() => submit(id, docId, link, onAgentAttractionClick)}
            className="block mt-2 no-underline text-gray-600 "
          >
            <p className="text-xl font-semibold text-white -mb-[.5rem]">
              {title}
            </p>
            <p className="mt-3 -mb-10 text-sm text-white">
              {description}
            </p>
          </div>
        </div>
          <div
            onClick={() => submit(id, docId, link, onAgentAttractionClick)}
            className="flex justify-end text-[.8rem] no-underline text-white"
          >
            View Page<span>&rarr;</span>
          </div>
      </div>
    </div>
  );
};

interface CardGridProps {
  data: CardProps[];
  onAgentAttractionClick: () => void;
}

const CardGrid: React.FC<CardGridProps> = ({
  data,
  onAgentAttractionClick,
}) => (
  <div className="flex flex-wrap w-full">
    {data.map((card, index) => (
      <div
        className="flex-auto p-1 sm:p-3 w-full sm:w-5/12 md:w-1/5 lg:w-5/12 xl:w-1/6 max-w-[25%]"
        key={index}
      >
        <Card {...card} onAgentAttractionClick={onAgentAttractionClick} />
      </div>
    ))}
  </div>
);

export default CardGrid;
