import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";

const EditProfile = () => {
  const supabase = supabaseConnect();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    jobTitle: "",
    phoneNumber: "",
  });
  const [profile, setProfile] = useState<any>(null);
  const [coverImage, setCoverImage] = useState<any>(null);

  useEffect(() => {
    const getUserProfile = async () => {
      const user = await supabase.auth.getSession();
      const { data, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", user.data.session?.user.id);

      setProfile(data);

      if (error) {
        console.log(error);
      }

      setFormData({
        firstName: data?.[0].first_name,
        lastName: data?.[0].last_name,
        email: data?.[0].email,
        jobTitle: data?.[0].job_title,
        phoneNumber: data?.[0].phone,
      });
    };
    getUserProfile();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (formData.email != profile?.[0].email) {
      const { data: user, error } = await supabase.auth.updateUser({
        email: formData.email,
      });
      alert("Please check your email to finish updating your email address");
    }

    console.log(formData, 'form data')

    const { data, error } = await supabase
      .from("profiles")
      .update({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        job_title: formData.jobTitle,
        phone: Number(formData.phoneNumber),
        profile_image_url: coverImage || profile?.[0].profile_image_url,
      })
      .eq("id", profile?.[0].id);

    if (error) {
      console.log(error);
    }

    return (window.location.href = "/");
  };

  const getAllImages = async () => {
    const { data, error } = await supabase.storage.from("media").list();

    if (error) alert(error?.message);

    console.log(data);

    return data;
  };

  const handleUpload = async (e: any) => {
    const file = e.target.files[0];

    // console.log(file);

    const filename = `${Math.floor(Math.random() * 1000000000)}`;

    if (file) {
      const { data, error } = await supabase.storage
        .from("media")
        .upload(`${filename}.${file.name}`, file);

      const { data: publicUrl } = await supabase.storage
        .from("media")
        .getPublicUrl(`${filename}.${file.name}`);

      if (error) {
        console.log(error);
      }
      setCoverImage(publicUrl.publicUrl);

      const images = getAllImages();

      return images;
    }
    return "no file";
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-left">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5 text-black  bg-gradient-to-tl from-gray to-black">
          {/* The left side of the screen would have similar content/design as signup page */}
          {/* ... */}
        </div>
        <div className=" flex justify-center  bg-white  sm:w-1/2 w-11/12 sm:p-5 p-1 m-auto rounded-md">
          <div className="w-fit md:w-full">
            <h1 className="text-xl font-bold  font-Poppins">Profile</h1>
            <img className="rounded-full w-[8rem] p-[1rem]" src={profile?.[0].profile_image_url || "https://via.placeholder.com/150" } alt="" />
            <div className="mt-[1rem]">
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleUpload(e)}
                className="pb-4 focus:outline-none rounded-md  border-[.2px] border-lightGray"
                id="grid-description"
              />
            </div>
            <form
              className="md:w-full space-y-6 z-[10]"
              onSubmit={handleSubmit}
            >
              <div>
                <label className="text-sm">First Name</label>
                <input
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                  type="text"
                />
              </div>

              <div>
                <label className="text-sm">Last Name</label>
                <input
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                  type="text"
                />
              </div>
              <div>
                <label className="text-sm">Email Address</label>
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                  type="email"
                />
              </div>
              <div>
                <label className="text-sm">Job Title</label>
                <input
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                  type="text"
                />
              </div>
              <div>
                <label className="text-sm">Phone Number</label>
                <input
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="w-full h-10 mt-1 focus:outline-none rounded-md border-solid border-[.2px] border-lightGray"
                  type="number"
                />
              </div>
              <button
                className="w-full h-10 mt-7 text-white border-none bg-lightBlue rounded-md cursor-pointer"
                type="submit"
              >
                Update Profile
              </button>
            </form>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default EditProfile;
