import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import {
  communityNameLogo,
  backGroundLinesLoginPage2,
  phone,
  circle,
  circleColor,
  test,
} from "../../assets/index";
import { redirect } from "react-router-dom";
import axios from "axios";

interface User {
  id: string;
}

const SignUp = () => {
  const [errors, setErrors] = useState<any>([]);
  const [profileRoles, setProfileRoles] = useState<any>([]);
  const [newProfileRoles, setNewProfileRoles] = useState<any>("Agent");
  const supabase = supabaseConnect();

  useEffect(() => {
    const getProfileRoles = async () => {
      const { data: profileRole, error: profileRoleError } = await supabase
        .from("role")
        .select("*");

      if (profileRoleError) {
        console.log(profileRoleError);
      }

      // filter out admin role
      const filteredProfileRoles = profileRole?.filter(
        (role: any) => role.role_name !== "Admin" && role.role_name !== "Staff"
      );

      setProfileRoles(filteredProfileRoles);
    };

    getProfileRoles();
  }, []);

  // build supabase login functionality here
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // prevent the default form submission behavior
    const formData = new FormData(event.currentTarget); // get the form data
    const email = formData.get("email") as string; // get the email value
    const password = formData.get("password") as string; // get the password value
    const confirmPassword = formData.get("confirm password") as string; // get the password value
    const firstName = formData.get("first name") as string; // get the password value
    const lastName = formData.get("last name") as string; // get the password value
    const phone = formData.get("phone") as string; // get the password value

    let newPhone = Number(phone);

    if (password !== confirmPassword) {
      setErrors([...errors, { message: "Passwords do not match" }]);
      return;
    }
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      if (firstName === "") {
        setErrors([...errors, { message: "First name is required" }]);
      }
      if (lastName === "") {
        setErrors([...errors, { message: "Last name is required" }]);
      }
      if (email === "") {
        setErrors([...errors, { message: "Email is required" }]);
      }
      if (password === "") {
        setErrors([...errors, { message: "Password is required" }]);
      }
      if (confirmPassword === "") {
        setErrors([...errors, { message: "Confirm password is required" }]);
      }
      return;
    }
    if (errors.length === 0) {
      try {
        let response = await supabase.auth.signUp({ email, password });
        console.log(response.data, "response");
        if (response.data?.user) {
          let confirmedUser = response.data.user;

          // select the user's profile data
          const { data: profile, error: profileError } = await supabase
            .from("profiles")
            .select()
            .eq("id", confirmedUser.id);

          console.log(profile, "profile");

          // Check if there was an error
          if (profileError) {
            console.log(profileError);
            return;
          }

          // Update the user's profile data
          const { data: updatedProfile, error: updateError } = await supabase
            .from("profiles")
            .update({
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: newPhone,
            })
            .eq("id", confirmedUser.id);

          console.log(updatedProfile);

          // Check if there was an error
          if (updateError) {
            console.log(updateError);
            return;
          }
          console.log(profileRoles);

          const findRoleInProfileRole = profileRoles?.find(
            (role: any) => role.role_name === newProfileRoles
          );

          const { data: profileRole, error: profileRoleError } = await supabase
            .from("profile_role")
            .insert([
              {
                user_id: confirmedUser.id,
                role_id: findRoleInProfileRole.id,
              },
            ])
            .eq("user_id", confirmedUser.id)
            .select("*");

          console.log(confirmedUser);

          if (profileRoleError) {
            console.log(profileRoleError);
          }
          // send user to login
          let emailBody = `
    <html>
    <head>
        <style>
            body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
            }
            .metric {
                margin-bottom: 10px;
            }
            .title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .metric-title {
                font-weight: bold;
            }
        </style>
    </head>
    <body>
        A new Community member has signed up to the portal. They are needing their account activated.

        <a href="https://app.realcommunity.com/users/edit/${confirmedUser.id}">Click here to go to their account and activate it.</a>

    </body>
    </html>
    `;
          const adminEmail = "emily.stone@realcommunity.com";
          // const adminEmail = "kyle.parkin@realcommunity.com";
          console.log(emailBody, "emailBody");
            await axios
              .post(
                "https://realcommunityportal-production-f1b9.up.railway.app/api/email/send",
                // "http://localhost:8000/api/email/send",
                {
                  emailBody,
                  adminEmail,
                }
              )
              .then((response) => {
                console.log(response);
                window.location.href = "/login";
              })
              .catch((error) => {
                console.log(error);
              });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="flex h-screen flex w-full bg-white">
        <div className="relative md:flex w-1/2 hidden flex-col items-center h-full bg-black">
          <div className="flex-1">
            <img
              className="flex mt-[4rem] pb-[4rem] max-w-full h-auto"
              src={communityNameLogo}
              alt="community-name-logo"
            />
          </div>
          <div className="absolute z-10 inset-y-1/4">
            <img
              className="flex pb-[1rem] max-w-full h-auto"
              src={phone}
              alt="phone-img"
            />
          </div>
          <img
            className="absolute bottom-0 max-w-2/5 w-[45rem] h-[40rem]"
            src={backGroundLinesLoginPage2}
            alt="background lines"
          />
          <div className="absolute inset-y-1/4">
            <img
              className="w-[35rem] max-w-full "
              src={test}
              alt="black-sphere"
            />
          </div>
          {/* <div className="absolute">
                        <img className="mt-[15rem] pb-[2rem] w-[35rem] opacity-20 max-w-full h-auto" src={circle} alt="community name logo" />
                    </div> */}

          <div className="text-black max-lg:text-[1.5rem] max-xl:text-[1.9rem] text-[2.5rem] text-center overflow-auto max-lg:mb-16 max-xl:mb-10 mb-5">
            <span className="font-inter font-extralight">
              Redefining the future <br />
              of real estate
            </span>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center bg-white md:px-12 lg:px-12  w-full  rounded-lg shadow  md:mt-0 xl:p-0 overflow-y-auto mb-5 ">
          <h1 className="mb-3 sm:text-2xl text-xl font-bold text-gray-900 lg:text-3xl text-center ">
            Create account
          </h1>
          <p className="mb-3 max-sm:mb-0 text-gray-500 dark:text-gray-400 text-center ">
            For business, brand or celeberty.
          </p>

          {errors.length > 0 && (
            <div className="bg-red-300 ml-center mt-[1.5rem] text-red-500 border-solid border-[1px]">
              {errors.map((error: any, i: any) => (
                <p key={i} className="pl-[1rem]">
                  {error.message}
                </p>
              ))}
            </div>
          )}
          <form
            className="mt-8"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="flex flex-col mb-6 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
              <div className="w-full md:mr-12 max-xs:mx-5">
                <label className="block mb-2 text-sm font-medium text-gray">First name</label>
                <input
                  name="first name"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                />
              </div>
              <div className="w-full md:ml-12 max-xs:mx-5">
              <label className="block mb-2 text-sm font-medium text-gray">Last name</label>
                <input
                  name="last name"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                />
              </div>
            </div>

            <div className="flex flex-col mb-6 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
              <div className="w-full md:mr-12 max-xs:mx-5">
                <label className="block mb-2 text-sm font-medium text-gray">Email Address</label>
                <input
                  name="email"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="tel"
                />
              </div>
              <div className="w-full md:ml-12 max-xs:mx-5">
                <label className="block mb-2 text-sm font-medium text-gray ">Phone number</label>
                <input
                  name="phone"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                />
              </div>
            </div>

            <div className="flex flex-col mb-6 space-y-6 md:flex-row md:space-y-0 md:space-x-6">
              <div className="w-full md:mr-12 max-xs:mx-5">
                <label className="block mb-2 text-sm font-medium text-gray">Password</label>
                <input
                  name="password"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="password"
                  onChange={(e) => {
                    setErrors([]);
                  }}
                />
              </div>
              <div className="w-full md:ml-12 max-xs:mx-5">
                <label className="block mb-2 text-sm font-medium text-gray">Confirm Password</label>
                <input
                  name="confirm password"
                  className="max-sm:w-80 max-xs:w-60 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder:text-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="password"
                  onChange={(e) => {
                    setErrors([]);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
              <div className="w-full max-sm:text-center">
                <label
                  className="block uppercase tracking-wide text-black text-xs font-bold mb-2"
                  htmlFor="grid-description"
                >
                  What is your role with Community?
                </label>
                {profileRoles?.map((option: any, index: number) => (
                  <label key={index} className="p-2 pl-0 sm:pl-2">
                    <input
                      type="radio"
                      value={option.role_name}
                      checked={newProfileRoles === option.role_name}
                      onChange={(e) => {
                        setNewProfileRoles(e.target.value);
                      }}
                      name="tag"
                      className="mr-2"
                    />
                    {option.role_name}
                    <br className="sm:hidden block last:hidden" />
                  </label>
                ))}
              </div>
            </div>
            <button
              className="w-full h-10 mt-7 text-white border-none bg-lightBlue rounded-md cursor-pointer col-span-1 max-xs:mr-5"
              type="submit"
            >
              Create account
            </button>
          </form>
          <div className="text-sm mt-3 font-medium text-gray-500 dark:text-gray-400">
              Already have an account?
            <a
              href="/login"
              className="ml-1  text-blue-700 text-lightblue hover:underline font-bold"
            >
              Sign in
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
