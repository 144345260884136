import React from "react";
import { magnifyingglass } from "../../assets";

const Search = () => {
  return (
    <div className="bg-gainsboro bg-[#1d2025] px-4 w-fit rounded-r-lg cursor-pointer" role="button">
      <div className="flex gap-4">
        <p className="">Search</p>
        <img
          className=""
          alt=""
          src={magnifyingglass}
        />
      </div>
    </div>
  );
};

export default Search;
