// @ts-nocheck
import { SlowBuffer } from "buffer";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import supabaseConnect from "../../utils/supabaseConnect";
import convertToTimestamptz from "../../utils/timeStampConvert";
import getEvents from "../../utils/getEvents";
import { start } from "repl";

// todo: need to finished error handling and validation Solution is to map throught the errors array looking for the field name and then displaying the message

const supabase: any = supabaseConnect();

const NewEventModal = ({
  showModal,
  setShowModal,
  setList,
  setEventData,
  activeEvent,
}: {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  setList: (list: any) => void;
  setEventData: (eventData: any) => void;
  activeEvent: any;
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [eventDetails, setEventDetails] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [errors, setErrors] = useState([]) as any[];
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const deleteButtonRef = useRef(null);

  const [event, setEvent] = useState({
    title,
    description,
    eventDetails,
    location,
    startDate,
    endDate,
    startTime,
    endTime,
  });

  const clearInput = () => {
    setTitle("");
    setDescription("");
    setEventDetails("");
    setLocation("");
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");
  };

  interface Error {
    field: string;
    message: string;
  }


  const submit = async () => {
    // Validation rules
    const validationRules = [
      { field: "title", message: "Title cannot be empty" },
      { field: "description", message: "Description cannot be empty" },
      { field: "eventDetails", message: "Event Details cannot be empty" },
      { field: "location", message: "Location cannot be empty" },
      { field: "startDate", message: "Start Date cannot be empty" },
      { field: "endDate", message: "End Date cannot be empty" },
      { field: "startTime", message: "Start Time cannot be empty" },
      { field: "endTime", message: "End Time cannot be empty" },
    ];
    let isErrors = false;

    // Initial object containing input values
    const inputValues = {
      title,
      description,
      eventDetails,
      location,
      startDate,
      startTime,
    };


    const { startTimestamptz, endTimestamptz } = convertToTimestamptz(
      startDate,
      startTime
    );

    const getEventsData = async () => {
      const { data, error } = await supabase.from("event").select("*").limit(3);
      if (error) {
        console.log(error);
      } else {
        const sortedData = data?.sort((a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        });
        // filter out events that have already passed
        const currentDate = new Date();
        const filteredData = sortedData?.filter((event: any) => {
          return new Date(event.start_date) >= currentDate;
        });
        setEventData(filteredData);
      }
    };

    // Perform validation based on input values
    validationRules.forEach((rule) => {
      if (inputValues[rule.field as keyof typeof inputValues] === "") {
        setErrors((errors: Error[]) => [...errors, rule]);
        isErrors = true;
      }
    });

    // // Additional validation for time and dates
    // if (startTime !== "" && endTime !== "" && startTime > endTime) {
    //   setErrors((errors: Error[]) => [
    //     ...errors,
    //     { field: "endTime", message: "End Time cannot be before Start Time" },
    //   ]);
    //   isErrors = true;
    // }

    // if (startDate !== "" && endDate !== "" && startDate > endDate) {
    //   setErrors((errors: Error[]) => [
    //     ...errors,
    //     { field: "endDate", message: "End Date cannot be before Start Date" },
    //   ]);
    //   isErrors = true;
    // }

    if (isErrors === false) {
      if (activeEvent?.length > 0) {
        const { data, error } = await supabase
          .from("event")
          .update({
            title, // required
            description,
            info: eventDetails,
            location,
            start_date: startTimestamptz, // required
            // end_date: endTimestamptz, // required
          })
          .eq("id", activeEvent[0].id);
        if (error) {
          console.log(error);
        } else {
          console.log(data);
          clearInput();
          setErrors([]);
          getEventsData();
          window.location.reload();
        }
      } else {
        console.log("startTimestamptz", startTimestamptz);
        const { data, error } = await supabase.from("event").insert({
          title, // required
          description,
          info: eventDetails,
          location,
          start_date: startTimestamptz, // required
          // end_date: endTimestamptz, // required
        });
        if (error) {
          console.log(error);
        } else {
          console.log(data);
          clearInput();
          setErrors([]);
          getEventsData();
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    console.log("activeEvent", activeEvent);

    const convertDate = (date: string) => {
      const dateObj = new Date(date);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // months are zero indexed
      const day = dateObj.getDate();
      return `${year}-${month < 10 ? `0${month}` : month}-${
        day < 10 ? `0${day}` : day
      }`;
    };

    const convertTime = (dateString: string) => {
      if (!dateString) return "";
      const dateObj = new Date(dateString);
      const hour = dateObj.getUTCHours();
      const minute = dateObj.getUTCMinutes();
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    if (activeEvent?.length > 0) {
        setTitle(activeEvent[0]?.title);
        setDescription(activeEvent[0]?.description);
        setEventDetails(activeEvent[0]?.info);
        setLocation(activeEvent[0]?.location);
        setStartDate(convertDate(activeEvent[0]?.start_date));
        setStartTime(convertTime(activeEvent[0]?.start_date));
    }
  }, [activeEvent]);

  const deleteEvent = async () => {
    const { data, error } = await supabase
      .from("event")
      .delete()
      .eq("id", activeEvent[0].id);
    if (error) {
      console.log(error);
    } else {
      console.log(data);
      clearInput();
      setErrors([]);
      window.location.reload();
    }
  };

  const handleDeleteClick = () => {
    console.log("showConfirmDelete", showConfirmDelete);
    if (showConfirmDelete) {
      // If already in confirmation mode, run the delete function
      deleteEvent();
    } else {
      // Show confirm delete message
      console.log("showConfirmDelete", showConfirmDelete);
      setShowConfirmDelete(true);
    }
  };

  // Reset the confirmation mode if needed elsewhere in your component
  const resetConfirmation = () => {
    setShowConfirmDelete(false);
  };

  const handleClickOutside = (event) => {
    if (
      showConfirmDelete &&
      deleteButtonRef.current &&
      !deleteButtonRef.current.contains(event.target)
    ) {
      resetConfirmation();
    }
  };

  // Add event listeners when component mounts and handles the conditional logic
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);

    // Clean up event listener to prevent memory leaks
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showConfirmDelete]); // Only re-run if showConfirmDelete changes

  const editEvent = async () => {
    console.log("editEvent", 'testing');
    // check validation
    const validationRules = [
      { field: "title", message: "Title cannot be empty" },
      { field: "description", message: "Description cannot be empty" },
      { field: "eventDetails", message: "Event Details cannot be empty" },
      { field: "location", message: "Location cannot be empty" },
      { field: "startDate", message: "Start Date cannot be empty" },
      { field: "startTime", message: "Start Time cannot be empty" },
    ];
    let isErrors = false;
    if (isErrors === false) {
      const { data, error } = await supabase
        .from("event")
        .update({
          title, // required
          description,
          info: eventDetails,
          location,
          start_date: convertToTimestamptz(startDate, startTime).startTimestamptz, // required
          // end_date: convertToTimestamptz(endDate, endTime).endTimestamptz, // required
        })
        .eq("id", activeEvent[0].id);
      if (error) {
        console.log(error);
      } else {
        // console.log(data);
        clearInput();
        setErrors([]);
        window.location.reload();
      }
    } else {
      validationRules.forEach((rule) => {
        if (inputValues[rule.field as keyof typeof inputValues] === "") {
          setErrors((errors: Error[]) => [...errors, rule]);
          isErrors = true;
        }
      });
    }
  }

  const convertTime = (date: string) => {
    // create a date obj for colorado time zone
    const dateObj = new Date(date);
    const hour = dateObj.getHours();
    const minute = dateObj.getMinutes();

    return `${hour < 10 ? `0${hour}` : hour}:${
      minute < 10 ? `0${minute}` : minute
    }`;
  };


  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <div className="fixed z-40 inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            {/* Background overlay */}
            <Transition.Child
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="absolute inset-0 bg-gray bg-opacity-75 transition-opacity"
                aria-hidden="true"
                onClick={() => {
                  setShowModal(false);
                  setErrors([]);
                }}
              ></div>
            </Transition.Child>

            {/* Right sliding panel */}
            <Transition.Child
              enter="transform transition ease-in-out duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <section
                className={`modal-section absolute h-screen right-0 pl-10 max-w-full flex sm:pl-16 transition-all duration-500 z-50 text-black`}
              >
                <div className="w-screen sm:max-w-md ">
                  <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                    {/* Panel header */}
                    <div className="px-4 py-1 bg-white sm:px-6">
                      <div className="flex items-start justify-between">
                        <h3
                          className="text-lg font-medium text-gray"
                          id="modal-headline"
                        >
                          Add Event
                        </h3>
                        <div className="ml-3 h-7 flex items-center py-[1.4rem]">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray hover:text-gray focus:outline-none border-none cursor-pointer"
                            onClick={() => {
                              setShowModal(false);
                              setErrors([]);
                              clearInput();
                            }}
                          >
                            <AiOutlineClose className="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Panel body */}
                    <div className="relative flex-1 py-6 pl-6 pr-10">
                      <div className="mt-2">
                        <div className="text-sm text-gray">
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "title"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "title"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="title"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Title:
                            </label>
                            <input
                              type="text"
                              id="title"
                              placeholder="Title"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setTitle(e.target.value)}
                              value={title}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "description"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "description"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="description"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Description:
                            </label>
                            <input
                              type="text"
                              id="description"
                              placeholder="Description"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setDescription(e.target.value)}
                              value={description}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "eventDetails"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "eventDetails"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="eventDetails"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Event Details:
                            </label>
                            <textarea
                              id="eventDetails"
                              placeholder="Event Details"
                              className="shadow appearance-none border rounded w-full h-[5rem] text-gray leading-tight focus:outline-none focus:shadow-outline px-2 py-2 resize-none overflow-y-auto"
                              onChange={(e) => setEventDetails(e.target.value)}
                              value={eventDetails}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "location"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "location"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="location"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Location:
                            </label>
                            <input
                              type="text"
                              id="location"
                              placeholder="Location"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setLocation(e.target.value)}
                              value={location}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "startDate"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "startDate"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="date"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Start Date:
                            </label>
                            <input
                              type="date"
                              id="date"
                              placeholder="Date"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setStartDate(e.target.value)}
                              value={startDate}
                            />
                          </div>
                          <div className="mb-4">
                            {errors?.length > 0 &&
                              errors.find(
                                (error: Error) => error.field === "startTime"
                              ) && (
                                <div className="flex items-start justify-between -mb-[.5rem]">
                                  <h4
                                    className="text-lg font-medium text-red-500"
                                    id="modal-headline"
                                  >
                                    {
                                      errors.find(
                                        (error: Error) =>
                                          error.field === "startTime"
                                      ).message
                                    }
                                  </h4>
                                </div>
                              )}
                            <label
                              htmlFor="time"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              Start Time:
                            </label>
                            <input
                              type="time"
                              id="time"
                              placeholder="Time"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setStartTime(e.target.value)}
                              value={startTime}
                            />
                          </div>
                          {/* <div className="mb-4">
                          {errors.length > 0 && errors.find((error: Error) => error.field === "endDate ") && (
                              <div className="flex items-start justify-between -mb-[.5rem]">
                                <h4
                                  className="text-lg font-medium text-red"
                                  id="modal-headline"
                                >
                                  {errors.find((error: Error) => error.field === "endDate ").message}
                                </h4>
                              </div>
                            )}
                            <label
                              htmlFor="date"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              End Date:
                            </label>
                            <input
                              type="date"
                              id="date"
                              placeholder="Date"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setEndDate(e.target.value)}
                              value={endDate}
                            />
                          </div>
                          
                          <div className="mb-4">
                          {errors.length > 0 && errors.find((error: Error) => error.field === "endTime") && (
                              <div className="flex items-start justify-between -mb-[.5rem]">
                                <h4
                                  className="text-lg font-medium text-red"
                                  id="modal-headline"
                                >
                                  {errors.find((error: Error) => error.field === "endTime").message}
                                </h4>
                              </div>
                            )}
                            <label
                              htmlFor="time"
                              className="block text-gray text-sm font-bold mb-2"
                            >
                              End Time:
                            </label>
                            <input
                              type="time"
                              id="time"
                              placeholder="Time"
                              className="shadow appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              onChange={(e) => setEndTime(e.target.value)}
                              value={endTime}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>

                    {/* Panel footer */}
                    <div className="flex-shrink-0 px-8 py-4 flex sm:flex-row flex-col sm:gap-0 gap-2 justify-end">
                      <button
                        type="button"
                        onClick={() => {
                          setShowModal(false);
                          setErrors([]);
                          clearInput();
                        }}
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray hover:bg-gray hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                      >
                        Cancel
                      </button>
                      {/* <button
                        type="button"
                        className=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Save
                      </button> */}
                      {/* Create a save button that if activeEvent then it saves the event else it runs the submit() */}
                      {activeEvent?.length > 0 ? (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            editEvent();
                          }}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-500 text-base font-medium text-white hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            submit();
                          }}
                        >
                          Save
                        </button>
                      )}
                      {activeEvent?.length > 0 && (
                        <button
                          type="button"
                          ref={deleteButtonRef}
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green sm:ml-3 sm:w-auto sm:text-sm cursor-pointer"
                          onClick={() => {
                            handleDeleteClick();
                          }}
                        >
                          {showConfirmDelete ? "Confirm Delete?" : "Delete"}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default NewEventModal;
