import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import CardGrid from "./ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";




const ResourcesPageHome = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [userRole, setUserRole] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>([
    {
      id: 1,
      title: "Resources for Everyone",
      description: "Community Resources for Everyone",
      body: "This is the body of the resource",
      tag: "Resource",
      cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      tag_id: 1,
    },
    {
      id: 2,
      title: "Resources for Agents",
      description: "Community Resources for Agents",
      body: "This is the body of the resource",
      tag: "Resource",
      cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      tag_id: 1,
    },
    {
      id: 3,
      title: "Resources for Staff",
      description: "Community Resources for Staff",
      body: "This is the body of the resource",
      tag: "Resource",
      cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      tag_id: 1,
    },
    {
      id: 7,
      title: "Agent Attraction Resources",
      description: "Agent Attraction Resources",
      body: "Agent Attraction Resources",
      tag: "Resource",
      cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      tag_id: 1,
    }
  ]);
  const [activePageData, setActivePageData] = useState<any>([]);
  const supabase = supabaseConnect();

  const addAgentAttractionResources = async () => {

    const { data: linkData, error: linkDataError } = await supabase
      .from('link')
      .select('*')
      .eq('parent_directory', 'Agent Attraction Resources')

    if (linkDataError) console.log("error", linkDataError);

    const agentResources = linkData?.map((item: any) => ({
      id: item.id,
      title: item.name,
      description: item.description,
      body: item.description,
      tag: item.tag,
      cover_image: item.cover_image,
      tag_id: 10,
      link: item.link,
    })) ?? [];

    setPageData((prevData: typeof pageData) => [...agentResources]);
  };



  const handleInput = () => {
    if (divRef.current) {
      setSearchTerm(divRef.current.innerText);
    }
  };

  useEffect(() => {
    const getUserPerms = async () => {
      let authUser;
      const { data: { user } } = await supabase.auth.getUser()
      console.log(user);
      authUser = user;

      const { data: profileRoleData, error: profileRoleDataError } = await supabase
        .from('profile_role')
        .select('*')
        .eq('user_id', authUser?.id)
        .single();
      console.log(profileRoleData);

      if (profileRoleDataError) console.log("error", profileRoleDataError);

      const { data: roledata, error: roledataError } = await supabase
        .from('role')
        .select('*')
        .eq('id', profileRoleData?.role_id)
        .single();
      console.log(roledata);

      if (roledataError) console.log("error", roledataError);

      return roledata?.role_name;

    }
    const checkPerms = async () => {
      const userRole = await getUserPerms();

      switch (userRole) {
        case "Admin":
        case "Staff":
          setUserRole(userRole);
          break;
        case "Agent":
          setUserRole("Agent");
          setPageData([
            {
              id: 1,
              title: "Resources for Everyone",
              description: "Community Resources for Everyone",
              body: "This is the body of the resource",
              tag: "Resource",
              cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
              tag_id: 1,
            },
            {
              id: 2,
              title: "Resources for Agents",
              description: "Community Resources for Agents",
              body: "This is the body of the resource",
              tag: "Resource",
              cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
              tag_id: 1,
            },
            {
              id: 8,
              title: "Agent Attraction Resources",
              description: "Agent Attraction Resources",
              body: "Agent Attraction Resources",
              tag: "Resource",
              cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
              tag_id: 1,
            },
          ]);
          break;
        default:
          setUserRole(null);
      }

      // Fetch link resources data for everyone
      const { data: linkData, error: linkDataError } = await supabase
        .from('link')
        .select('*')
        .eq('parent_directory', 'Resource_Home_Page');

      if (linkDataError) console.log("error", linkDataError);

      const resources = linkData?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image,
        tag_id: 10,
        link: item.link,
      })) ?? [];

      setPageData((prevData: typeof pageData) => [...prevData, ...resources]);
    };

    checkPerms();
    // getData();
  }, []);

  const search = async () => {
    try {
      // Execute search queries for each table
      setLoading(true);
      const titleResults = await Promise.all([
        supabase
          .from("google_docs")
          .select("*")
          .textSearch("name", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("google_docs")
          .select("*")
          .textSearch("content", searchTerm, { type: "websearch" }),
      ]);
      const linkResultsTitle = await Promise.all([
        supabase
          .from("link")
          .select("*")
          .textSearch("name", searchTerm, { type: "websearch" }),
      ]);
      const linkResultsDescription = await Promise.all([
        supabase
          .from("link")
          .select("*")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);

      console.log(
        "Search results: ",
        titleResults,
        bodyResults
      );

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
        ...(linkResultsTitle[0]?.data ?? []),
        ...(linkResultsDescription[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      // filter out results based on userRole 
      if (userRole === "Agent") {
        results = results.filter((item: any) => item.topLevelParentName === "Agent Resources" || item.topLevelParentName === "_Everyone");
      }

      console.log("Search results: ", results);

      results = results.map((item) => ({
        ...item,
        title: item.name.substring(0, 25),
        body: item.content,
        description: item.content.substring(0, 30),
        cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_name: "Resource",
        link: item.link,
      }));



      setPageData(results);
      setLoading(false);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  const handleClick = () => {
    return window.location.href = "/page";
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer" onClick={handleClick}>
              Resource Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Resources"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          <CardGrid
            data={pageData}
            onAgentAttractionClick={addAgentAttractionResources}
          />
          {pageData.length === 0 && !loading && (
            <div className="mt-10 ml-4 text-center">
              No resource results found for "{searchTerm}"
            </div>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default ResourcesPageHome;
