import { useEffect } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import { useState } from "react";

// this page is for users who have not activated their account yet
const Inactive = () => {
    const supabase = supabaseConnect();
    const [users, setUsers] = useState<any>(null);
    const [profile, setProfile] = useState<any>(null);

    useEffect(() => {
        const getUser = async () => {
            const { data: user, error } = await supabase.auth.getSession();
            if (error) return console.log(error);
            if (!user) {
              return (window.location.href = "/login");
            }
            setUsers(user);
            if (!user.session) {
              return (window.location.href = "/login");
            }
            console.log(users?.session?.user.id);
            return user;
          };
          const getProfile = async (id: string) => {
            const { data: profile, error } = await supabase
              .from("profiles")
              .select("*")
              .eq("id", id);
            setProfile(profile);
            if (error) return console.log(error);
            console.log(profile);
            setProfile(profile);
            return profile;
          };
          const checkActive = async () => {
            const { data, error } = await supabase
              .from("profiles")
              .select("*")
              .eq("id", users?.session?.user.id);
            if (error) return console.log(error);
            if (data?.[0].active === false || !data?.[0].active) {
              window.location.href = "/inactive";
            }
            if (data?.[0].active === true) {
                console.log("active");
                window.location.href = "/login";
            }
            console.log(data);
          }
            getUser();
            getProfile(users?.session?.user.id);
            checkActive();
    }
    , []);
    
    return (
        <div className="flex flex-col items-center justify-center h-screen text-white">
        <h1>Your account is not activated</h1>
        <p>
            If you just signed up, it could take up to 24 hours for your account to be activated. If you have been waiting for more than 24 hours or have any questions, please contact community at: 

        </p>
        <a href="mailto:emily.stone@realcommunity.com" className="text-blue-200">
             Support
        </a>
        </div>
    )
}

export default Inactive