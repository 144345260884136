const convertToTimestamptz = (
  startDate: string,
  startTime: string
): { startTimestamptz: string; endTimestamptz: string } => {
  // Convert start date and time to UTC timestamp.
  let startTimestamp = new Date(`${startDate}T${startTime}:00Z`).getTime();
  let endTimestamp = startTimestamp + 3600000; // Plus one hour in milliseconds.

  // Convert timestamps back to formatted strings.
  const formatTimestamp = (timestamp: number): string => {
    // Create a new Date object in UTC time.
    const date = new Date(timestamp);
    // Format the date and time in 'YYYY-MM-DD HH:MM:SS+00' format.
    const formatted =
      date.getUTCFullYear() +
      "-" +
      String(date.getUTCMonth() + 1).padStart(2, "0") + // Months are 0-based.
      "-" +
      String(date.getUTCDate()).padStart(2, "0") +
      " " +
      String(date.getUTCHours()).padStart(2, "0") +
      ":" +
      String(date.getUTCMinutes()).padStart(2, "0") +
      ":" +
      String(date.getUTCSeconds()).padStart(2, "0") +
      "+00"; // Hardcoded UTC offset as per your example.
    return formatted;
  };

  // Use formatTimestamp function to convert milliseconds timestamps to formatted strings.
  const startTimestamptz = formatTimestamp(startTimestamp);
  const endTimestamptz = formatTimestamp(endTimestamp);

  console.log("startTimestamptz", startTimestamptz);

  return { startTimestamptz, endTimestamptz };
};

export default convertToTimestamptz;
