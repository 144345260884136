import { info } from "console";
import React, { useState, FunctionComponent, useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import supabaseConnect from "../../../utils/supabaseConnect";

interface CardProps {
  first_name: string;
  last_name: string;
  email: string;
  phone: string; 
  job_title: string;
  id: number;
  profile_image_url: string;
}


// Define these states at a higher level, outside of the click handler

const Card: React.FC<CardProps> = ({
  first_name,
  last_name,
  email,
  phone,
  job_title,
  profile_image_url,
  id,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const supabase = supabaseConnect();
  const [users, setUsers] = useState([]) as any[];

  const handleCardClick = async (id: number ) => {
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", id)
      .limit(1);
    if (error) console.log("error", error);
    setUsers(data);
    window.location.href = `/users/edit/${id}`;
  };
  
  return (
    <>
      <div id="modal-root"></div>
      <div
        onClick={() => handleCardClick(id)}
        className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[18rem] bg-black cursor-pointer"
      >
        <div className="flex ">
          <img
            className="h-32 w-full object-cover"
            src={profile_image_url}
            alt=""
          />
        </div>
        <div className="flex-1 bg-black p-3 flex flex-col justify-between">
          <div className="flex-1 text-[1rem]">
            <div className="block mt-2 no-underline text-gray-600 ">
              <p className="text-xl font-semibold text-white -mb-[.3rem]">
                {first_name} {last_name}
              </p>
              <p className="mt-4 -mb-10 text-sm text-white">{job_title}</p>
            </div>
          </div>
          <div className="flex justify-end text-[.8rem] no-underline text-white">
            Edit Users Info <span>&rarr;</span>
          </div>
        </div>
      </div>
    </>
  );
};

interface CardGridProps {
  data: CardProps[];
}

const CardGrid: React.FC<CardGridProps> = ({ data }) => (
  <div className="flex flex-wrap responsive mb-52 w-full">
    {data?.map((card, index) => (
      <div className="flex-auto p-1 sm:p-3 w-full xs:w-5/12 sm:w-1/4 md:w-1/5 xl:w-1/6" key={index}>
        <Card {...card} />
      </div>
    ))}
  </div>
);

export default CardGrid;
