// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container a {
    color: #00bfff;
    text-decoration: none;
}
  
.content-container a:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .close-button {
    display: block;
    left: 5px; /* Updated for mobile */
  }
}

.close-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 10px; /* Changed from right to left */
  right: auto; /* Added to override any existing right property */
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/announcement/AnnouncementCSS.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;EACE;IACE,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;EACA;IACE,cAAc;IACd,SAAS,EAAE,uBAAuB;EACpC;AACF;;AAEA;EACE,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,SAAS;EACT,UAAU,EAAE,+BAA+B;EAC3C,WAAW,EAAE,kDAAkD;EAC/D,eAAe;AACjB","sourcesContent":[".content-container a {\n    color: #00bfff;\n    text-decoration: none;\n}\n  \n.content-container a:hover {\n    text-decoration: underline;\n}\n\n@media (max-width: 768px) {\n  .modal-content {\n    width: 100%;\n    height: 100%;\n    border-radius: 0;\n  }\n  .close-button {\n    display: block;\n    left: 5px; /* Updated for mobile */\n  }\n}\n\n.close-button {\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  border: none;\n  border-radius: 50%;\n  width: 30px;\n  height: 30px;\n  font-size: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 10px;\n  left: 10px; /* Changed from right to left */\n  right: auto; /* Added to override any existing right property */\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
