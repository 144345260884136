import supabaseConnect from "./supabaseConnect";

const getPages = async () => {
  const supabase = supabaseConnect();
  const { data, error } = (await supabase.from("page").select("*").limit(4));
  if (error) {
    throw new Error(error.message);
  }

  console.log(data);
  return data;
};

export default getPages;
