import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
// import Sidebar from "../../components/Sidebar/Sidebar";
// import { magnifyingglass } from "../../assets";
// import CardGrid from "./SearchCard";
// import supabaseConnect from "../../utils/supabaseConnect";
// import Search from "../dashboard/Search";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { magnifyingglass } from "../../../assets";
import CardGrid from "./UsersCards";
import supabaseConnect from "../../../utils/supabaseConnect";
import Search from "../../dashboard/Search";
import MainLayoutWrapper from "../../../components/layout/MainLayoutWrapper";

const Users = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const { query } = useParams<{ query: string }>();
  const [searchTerm, setSearchTerm] = useState(query);
  const supabase = supabaseConnect();
  const [data, setData] = useState([]) as any[];
  const [userAdmin, setUserAdmin] = useState(false);

  const getUserAdmin = async () => {
    const user = await supabase.auth.getSession();
    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", user.data.session?.user.id)
      .limit(1);
    if (error) console.log("error", error);
    if (data?.[0].role_id === 1) {
      setUserAdmin(true);
      return true;
    } else return false;
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //      const getAdmin = await getUserAdmin();
  //       if (!getAdmin) {
  //           window.location.href = "/";
  //       }
  //   }
  //   fetchData();
  // }, []);

  const search = async () => {
    try {
      // Execute search queries for each table
      const firstName = await Promise.all([
        supabase
          .from("profiles")
          .select("*")
          .textSearch("first_name", searchTerm || "", { type: "websearch" }),
      ]);
      const lastName = await Promise.all([
        supabase
          .from("profiles")
          .select("*")
          .textSearch("last_name", searchTerm || "", { type: "websearch" }),
      ]);
      console.log(firstName);
      if (firstName?.[0]?.data && firstName[0].data.length === 0) {
        const results = await Promise.all([
          supabase.from("profiles").select("*"),
        ]);

        return setData(results?.[0]?.data ?? []);
      }

      console.log("Search results: ", firstName[0]?.data);

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(firstName[0]?.data ?? []),
        ...(lastName[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);
      setData(results);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      return [];
    }
  };

  useEffect(() => {
    search();
  }, []);

  // todo: add functionality to the cardGrid and make them pull from db instead of the array above

  return (
    <div>
      <div className="flex w-full h-screen overflow-hidden text-white">
        <Sidebar />
        <MainLayoutWrapper>
          <div className="sm:p-5 p-3">
            <div className="w-full lg:text-5xl md:text-4xl sm:text-3xl text-2xl flex sm:justify-start justify-center">
              Search Results for Users
            </div>
            <div className="">
              <div className="relative mt-3 max-sm:pr-16">
                <input
                  className="py-3 pl-[60px] rounded-xl leading-[150%] inline-block md:w-[33rem] sm:w-[28rem] w-full bg-black focus:outline-none text-white border-none h-[1.5rem] text-[1rem] placeholder:font-medium"
                  placeholder="Search content goes here"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      search();
                    }
                  }}
                />

                <img
                  className="absolute top-[15px] left-[15px] w-5 h-5 overflow-hidden"
                  alt=""
                  src={magnifyingglass}
                />
              </div>
            </div>
            <CardGrid data={data} />
          </div>
        </MainLayoutWrapper>
      </div>
    </div>
  );
};

export default Users;
