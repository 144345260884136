// ChatBotPage.tsx
import React from 'react';
import ChatBot from '../../components/chatbot';
import Sidebar from '../../components/Sidebar/Sidebar';

const ChatBotPage: React.FC = () => {
  return (
    <div className="flex h-screen">
      <Sidebar />
      <div className="flex flex-col flex-1">
        <div className="flex-1">
          <ChatBot />
        </div>
      </div>
    </div>
  );
};

export default ChatBotPage;
