import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import CardGrid from "../resourcesPage/ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import { magnifyingglass, rectangle, communityLogo1 } from "../../assets";

const MarketingPageHome = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>([
    {
      id: 6,
      title: "Community Marketing Center",
      description: "Community Marketing Center",
      body: "Community Marketing Center",
      tag: "Marketing",
      link: "https://www.realdesigncenter.com",
      cover_image: communityLogo1,
      tag_id: 1,
    },
    {
      id: 8,
      title: "Marketing Center Training",
      description: "Passcode if asked: xc8K*t*%",
      body: "Marketing Center Training",
      tag: "Marketing",
      link: "https://us02web.zoom.us/rec/component-page?action=viewdetailpage&sharelevel=meeting&useWhichPasswd=meeting&clusterId=us02&componentName=need-password&meetingId=0DCPQ3SuvnUVRMAWE4s1PXapK36X2L1ZgsmC7LWxNFyLGds7oKrNcVAWC4sdkB4r.1Fm7pWn8BaO-Lhvx&originRequestUrl=https%3A%2F%2Fus02web.zoom.us%2Frec%2Fshare%2FTf3KA9fJwNFfuSC5qe_okdzx6KOCZp3v_2hSdkSJy6ePf0RUPAHTjSXqyfjFf_l0.fBXu0ZXGbrYmAk-g",
      cover_image: communityLogo1,
      tag_id: 1,
    },
    {
      id: 7,
      title: "Agent Attraction Resources",
      description: "Agent Attraction Resources",
      body: "Agent Attraction Resources",
      tag: "Resource",
      cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      tag_id: 1,
    }
  ]);
  const [activePageData, setActivePageData] = useState<any>([]);
  const supabase = supabaseConnect();

  const addAgentAttractionResources = async () => {

    const { data: linkData, error: linkDataError } = await supabase
      .from('link')
      .select('*')
      .eq('parent_directory', 'Agent Attraction Resources')
  
    if (linkDataError) console.log("error", linkDataError);
  
    const agentResources = linkData?.map((item: any) => ({
      id: item.id,
      title: item.name,
      description: item.description,
      body: item.description,
      tag: item.tag,
      cover_image: item.cover_image,
      tag_id: 10,
      link: item.link,
    })) ?? [];

    setPageData((prevData: typeof pageData) => [...agentResources]);
  };

  const handleInput = () => {
    if (divRef.current) {
      setSearchTerm(divRef.current.innerText);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const { data, error } = await supabase
        .from("page")
        .select("*")
        .eq("tag", "Marketing")
        .limit(20);
      if (error) console.log("error", error);
      else setPageData(data);
    };
    // getData();
  }, []);

  const search = async () => {
    try {
      // Execute search queries for each table
      setLoading(true);
      const titleResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Marketing")
          .textSearch("title", searchTerm, { type: "websearch" }),
      ]);
      const descriptionResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Marketing")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Marketing")
          .textSearch("body", searchTerm, { type: "websearch" }),
      ]);

      console.log(
        "Search results: ",
        titleResults,
        descriptionResults,
        bodyResults
      );

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(descriptionResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);
      setPageData(results);
      setLoading(false);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  // todo: add functionality to the cardGrid and make them pull from db instead of the array above

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Marketing Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Marketings"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          <CardGrid
            data={pageData}
            onAgentAttractionClick={addAgentAttractionResources}
          />
          {pageData.length === 0 && !loading && (
            <div className="mt-10 ml-4 text-center">
              No marketing results found for "{searchTerm}"
            </div>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default MarketingPageHome;
