import React, {useRef, useState} from 'react';


// CircleButton Component
const CreateNewFooterMenu = ({
  setShowEventModal
}: {
  setShowEventModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    // State to control the visibility of the menu
    const [menuVisible, setMenuVisible] = useState(false);
    const circleButtonRef = useRef(null);
  
    // Function to toggle menu visibility
    const toggleMenu = () => {
      setMenuVisible(!menuVisible);
    };
  
    // Function that pretend to create a new page
    const createNewPage = () => {
      console.log('Creating new page...');
      return (window.location.href = `/admin/addpage`);
      // Logic for creating a new page
    };
  
    // Function that pretend to create a new announcement
    const createNewAnnouncement = () => {
      console.log('Creating new announcement...');
        return (window.location.href = `/admin/addannouncement`);
      // Logic for creating a new announcement
    };

    const createNewEvent = () => {
      console.log('Creating new event...');
      setShowEventModal(true);
      return;
      // Logic for creating a new announcement
    }


  
    return (
      <div className="fixed bottom-10 right-10">
        <button
          className="flex items-center justify-center w-16 h-16 bg-blue-500 text-white rounded-full shadow-lg hover:bg-blue-400 focus:outline-none cursor-pointer"
          onClick={toggleMenu}
        >
          {/* Icon or Text for the circle button */}
          <span className='text-[1.5rem]'>+</span>
        </button>
        {menuVisible && (
          <div className="absolute xs:bottom-12 bottom-16 sm:right-20 xs:right-12 -right-7 bg-gray-800 border rounded-lg shadow-lg border-lg sm:w-80 xs:w-60 w-52 w- p-5 z-50">
            <ul>
              {/* <li
                className="hover:opacity-50 cursor-pointer list-none -ml-3 pb-2 z-10"
                onClick={createNewPage}
              >
                Upload New Resource
              </li> */}
              <li
                className="hover:opacity-50 cursor-pointer list-none -ml-[2.5rem] pb-2 pr-[1rem] z-10 border-solid border-b-2 border-gray-600 p-2"
                onClick={createNewAnnouncement}
              >
                Create New Announcement
              </li>
              <li
                className="hover:opacity-50 cursor-pointer list-none -ml-[2.5rem] pb-2 pr-[1rem] z-10 border-solid border-b-2 border-gray-600 p-2"
                onClick={createNewEvent}
              >
                Create New Event
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  };

export default CreateNewFooterMenu;