import React from "react";
import { calendar } from "../../assets";
import { pin } from "../../assets";
import supabaseConnect from "../../utils/supabaseConnect";

interface CalendarProps {
  eventData: {
    id: string;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    location: string;
    info: string;
    cover_image: string;
  };
  activeEvent: {
    id: string;
    title: string;
    description: string;
    created_at: string;
    info: string;
    cover_image: string;
  }[];
  setActiveEvent: (event: any) => void;
  setIsEventModalOpen: (event: boolean) => void;
}


const Calendar = ({ eventData, activeEvent, setActiveEvent, setIsEventModalOpen }: CalendarProps) => {
  // deconstruct the things out of eventData

  const parseDateString = (dateString: string | undefined) => {
    console.log("Received dateString:", dateString);
  
    if (!dateString) {
      console.log("dateString is falsy");
      return {
        day: 'N/A',
        month: 'N/A',
        year: 'N/A',
        dayOfWeek: 'N/A',
        time: 'N/A'
      };
    }
  
    try {
      // Split the string into date, time, and timezone offset
      const [datePart, timePart] = dateString.split(' ');
      const [year, month, day] = datePart.split('-');
      const [time] = timePart.split('+'); // Ignore the timezone offset for now
      const [hours, minutes] = time.split(':');
  
      console.log("Parsed parts:", { year, month, day, hours, minutes });
  
      const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      
      const daysOfWeek = [
        "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
      ];
  
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
      const dayOfWeek = daysOfWeek[date.getDay()];
  
      let hour = parseInt(hours);
      const ampm = hour >= 12 ? 'PM' : 'AM';
      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
  
      return {
        day: parseInt(day),
        month: months[parseInt(month) - 1],
        year: parseInt(year),
        dayOfWeek,
        time: `${hour}:${minutes} ${ampm}`
      };
    } catch (error) {
      console.error("Error parsing date:", error);
      console.error("Problematic dateString:", dateString);
      return {
        day: 'Error',
        month: 'Error',
        year: 'Error',
        dayOfWeek: 'Error',
        time: 'Error'
      };
    }
  };

  const { day, month, year, dayOfWeek, time } = parseDateString(eventData.start_date);

  const handleClick = async (e: React.MouseEvent) => {
    console.log("clicked", eventData.id);
    const supabase = supabaseConnect(); 
    const { data, error } = await supabase.from("event").select("*").eq("id", eventData.id);
    console.log(data);
    setActiveEvent(data);
    setIsEventModalOpen(true);
    return data;
  };



  return (
    <div className="w-full bg-white rounded-lg shadow-sm cursor-pointer p-3 mb-2" onClick={handleClick}>
      <div className="flex items-start">
        <div className="flex-shrink-0 mr-4 w-10 text-center">
          <p className="text-3xl font-bold text-blue-800 m-0 leading-tight">{day}</p>
        </div>
        <div className="flex-shrink-0 mr-4 w-20">
          <p className="text-sm font-medium text-gray-600 m-0">{dayOfWeek}</p>
          <p className="text-sm text-gray-500 m-0">{month}</p>
          <p className="text-sm text-gray-500 m-0">{year}</p>
        </div>
        <div className="flex-grow">
          <h3 className="text-sm font-semibold text-black m-0 mb-1 truncate">{eventData.title}</h3>
          <div className="flex items-center text-xs text-gray-600">
            <img src={calendar} alt="Time" className="w-3 h-3 mr-1" />
            <span>{time}</span>
          </div>
          <div className="flex items-center mt-0.5 text-xs text-gray-600">
            <img src={pin} alt="Location" className="w-3 h-3 mr-1" />
            <span className="truncate">{eventData.location}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
