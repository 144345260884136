import React, { useEffect, useState } from "react";
import supabaseConnect from "../../utils/supabaseConnect";
import { communityLogo1 } from "../../assets/index";

export interface CardProps {
  cover_image: string;
  title: string;
  description: string;
  id: number;
  tag_id: number;
  tag: string;
  docId?: string;
  tag_name?: string;  // Make this optional
  link?: string;  // Make this optional
  parent_directory: string;
  children?: CardProps[];
  body?: string;  // Add this to match the Resource type
}

const Card: React.FC<CardProps & { onCardClick: (item: CardProps) => void }> = ({
  cover_image,
  title,
  description,
  id,
  tag,
  docId,
  link,
  children,
  onCardClick,
}) => {
  const [tagName, setTagName] = useState<string | null>(null);

  // make description shorter if it's too long
  if (description && description.length > 50) {
    description = description.substring(0, 50) + "...";
  }

  const getTag = async (tag_name: string) => {
    const supabase = supabaseConnect();
    const { data: tag, error } = await supabase
      .from("tag")
      .select("*")
      .eq("tag_name", tag_name);

    if (error) return console.log(error);
    setTagName("Resource");
  };

  useEffect(() => {
    getTag(tag);
  }, [tag]);

  return (
    <div
      onClick={() => onCardClick({ cover_image, title, description, id, tag_id: 0, tag, docId, tag_name: "", link, parent_directory: "", children })}
      className="flex flex-col rounded-lg shadow-lg overflow-hidden h-[18rem] bg-black cursor-pointer w-full sm:max-w-sm"
    >
      <div className="flex ">
        <img
          className="h-24 w-full object-cover"
          src={communityLogo1}
          alt=""
        />
      </div>
      <div className="flex-1 bg-black p-3 flex flex-col justify-between">
        <div className="flex-1 text-[1rem]">
          <p className="text-sm font-medium text-blue-600 -mt-[.4rem] -mb-[.8rem]">
            <div className="no-underline text-tourquoise">
              {tagName}
            </div>
          </p>
          <div className="block mt-2 no-underline text-gray-600 ">
            <p className="text-xl font-semibold text-white -mb-[.5rem]">
              {title}
            </p>
            <p className="mt-3 -mb-10 text-sm text-white">
              {description}
            </p>
          </div>
        </div>
        <div className="flex justify-end text-[.8rem] no-underline text-white">
          {children ? "View Resources" : "View Page"}<span>&rarr;</span>
        </div>
      </div>
    </div>
  );
};

export interface CardGridProps {
  data: CardProps[];
  onCardClick: (item: CardProps) => void;
}

const CardGrid: React.FC<CardGridProps> = ({
  data,
  onCardClick
}) => {
  return (
    <div className="flex flex-wrap w-full -mx-2 sm:mx-0">
      {data.map((card, index) => (
        <div
          className="w-full p-2 sm:p-3 sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
          key={index}
        >
          <Card {...card} onCardClick={onCardClick} />
        </div>
      ))}
    </div>
  );
};

export default CardGrid;
