import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { magnifyingglass, rectangle, communityLogo1 } from "../../assets";
import CardGrid from "../resourcesPage/ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import LoadingSpinner from "../../components/LoadingSpinner";

const TrainingHomePage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<any>([]);
  const [activePageData, setActivePageData] = useState<any>([]);
  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching initial data
      const { data: linkData, error: linkDataError } = await supabase
        .from('link')
        .select('*')
        .eq('tag', 'Training');

      if (linkDataError) console.log("error", linkDataError);

      let trainingItems = linkData?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image || communityLogo1,
        tag_id: item.tag_id,
        link: item.link,
        parent_directory: item.parent_directory,
      })) ?? [];

      // Organize training items into a hierarchical structure
      const organizedTrainingItems = trainingItems.reduce((acc: any, item: any) => {
        if (!item.parent_directory || item.parent_directory === "Training_Home_Page") {
          acc.push(item);
        } else {
          const parentIndex = acc.findIndex((resource: any) => resource.title === item.parent_directory);
          if (parentIndex !== -1) {
            if (!acc[parentIndex].children) {
              acc[parentIndex].children = [];
            }
            acc[parentIndex].children.push(item);
          } else {
            acc.push({
              id: `parent_${item.parent_directory}`,
              title: item.parent_directory,
              description: `Training resources for ${item.parent_directory}`,
              tag: "Training",
              cover_image: communityLogo1,
              children: [item]
            });
          }
        }
        return acc;
      }, []);

      setPageData(organizedTrainingItems);
      setLoading(false); // Set loading to false when done fetching
    };

    fetchData();
  }, []);

  const search = async () => {
    try {
      setLoading(true);
      // Execute search queries for each table
      const titleResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("title", searchTerm, { type: "websearch" }),
      ]);
      const descriptionResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("page")
          .select("*")
          .eq("tag", "Training")
          .textSearch("body", searchTerm, { type: "websearch" }),
      ]);

      console.log(
        "Search results: ",
        titleResults,
        descriptionResults,
        bodyResults
      );

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(descriptionResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);
      setPageData(results);
      setLoading(false);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  const handleCardClick = (item: any) => {
    if (item.children) {
      setActivePageData(item.children);
    } else {
      // Handle opening the link or navigating to the resource
      window.open(item.link, "_blank");
    }
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer">
              Training Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Trainings"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                {loading ? <LoadingSpinner /> : <Search />}
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <CardGrid
                data={activePageData.length > 0 ? activePageData : pageData}
                onCardClick={handleCardClick}
              />
              {pageData.length === 0 && searchTerm && (
                <div className="mt-10 ml-4 text-center">
                  No training results found for "{searchTerm}"
                </div>
              )}
            </>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default TrainingHomePage;
