import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import Search from "../dashboard/Search";
import supabaseConnect from "../../utils/supabaseConnect";
import CalendarCards from "../../components/calendarv1Cards/CalenderCard";
import { get } from "http";

const CalendarV1 = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [calendarData, setCalendarData] = useState<any>([]);
  const supabase = supabaseConnect();
  const [isOpen, setIsOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState<any>();
  const [beingEdited, setBeingEdited] = useState(false);
  const [showEventModal, setShowEventModal] = useState(false);
  const onClose = () => setIsOpen(false);
  const title = "Event";

  const handleClick = () => {
    console.log("clicked");
  };

  const search = async () => {
    try {
      // Execute search queries for each table
      const titleResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("title", searchTerm, { type: "websearch" }),
      ]);
      const descriptionResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("description", searchTerm, { type: "websearch" }),
      ]);
      const locationResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("location", searchTerm, { type: "websearch" }),
      ]);
      const bodyResults = await Promise.all([
        supabase
          .from("event")
          .select("*")
          .textSearch("info", searchTerm, { type: "websearch" }),
      ]);

      console.log("Search results: ", titleResults, bodyResults);

      // Combine all results into a single array only having one of each item by id
      let results = [
        ...(titleResults[0]?.data ?? []),
        ...(bodyResults[0]?.data ?? []),
        ...(descriptionResults[0]?.data ?? []),
        ...(locationResults[0]?.data ?? []),
      ];

      console.log("Search results: ", results);

      // Remove duplicates
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );

      console.log("Search results: ", results);

      // results = results.map((item) => ({
      //   ...item,
      //   title: item.name.substring(0, 25),
      //   body: item.content,
      //   description: item.content.substring(0, 30),
      //   cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
      //   tag_name: "Resource",
      // }));

      // filter out events that have already passed
      const currentDate = new Date();
      results = results.filter((event: any) => {
        return new Date(event.start_date) >= currentDate;
      });

      setCalendarData(results);

      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      return [];
    }
  };

  useEffect(() => {
    const getCalendarData = async () => {
      const { data, error } = await supabase
        .from("event")
        .select("*")
        .order("id", { ascending: true });
      if (error) {
        console.log("Error fetching calendar data: ", error);
      } else {
        console.log("Calendar data: ", data);
        const sortedData = data?.sort((a: any, b: any) => {
          return (
            new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
          );
        });
        // filter out events that have already passed
        const currentDate = new Date();
        let filteredData = sortedData?.filter((event: any) => {
          return new Date(event.start_date) >= currentDate;
        });
        // make the title no longer the 20 characters
        filteredData = filteredData.map((item) => ({
          ...item,
          title: item.title.substring(0, 20) + "...",
          description: item.description.substring(0, 30),
          cover_image:
            "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
          tag_name: "Resource",
        }));

        setCalendarData(filteredData);
      }
    };
    getCalendarData();
  }, []);
  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3  flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer"  onClick={handleClick}>
              Events
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Events"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          {/* <CardGrid data={pageData} /> This is for data base */}
          {/* <CardGrid data={pageData} /> */}
          <CalendarCards
            data={calendarData}
            isOpen={isOpen}
            onClose={onClose}
            activeEvent={activeEvent}
            setBeingEdited={setBeingEdited}
            setShowEventModal={setShowEventModal}
            setActiveEvent={setActiveEvent}
            title={title}
          />
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default CalendarV1;
