import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import CardGrid, { CardProps } from "./ResourceCards";
import supabaseConnect from "../../utils/supabaseConnect";
import Search from "../dashboard/Search";
import MainLayoutWrapper from "../../components/layout/MainLayoutWrapper";
import LoadingSpinner from "../../components/LoadingSpinner";
import { magnifyingglass } from "../../assets";

interface Resource extends CardProps {
  body: string;
}

const ResourcesPageHome = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const divRef = useRef<HTMLDivElement>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [pageData, setPageData] = useState<Resource[]>([]);
  const [activePageData, setActivePageData] = useState<Resource[]>([]);
  const supabase = supabaseConnect();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true when fetching initial data
      const { data: { user } } = await supabase.auth.getUser();
      const userRole = await getUserPerms(user?.id);

      // Default resources
      const defaultResources: Resource[] = [
        {
          id: 1,
          title: "Resources for Everyone",
          description: "Community Resources for Everyone",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
        },
        {
          id: 2,
          title: "Resources for Agents",
          description: "Community Resources for Agents",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
        },
      ];

      if (userRole === "Staff" || userRole === "Admin") {
        defaultResources.push({
          id: 3,
          title: "Resources for Staff",
          description: "Community Resources for Staff",
          body: "This is the body of the resource",
          tag: "Resource",
          cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
          tag_id: 1,
          parent_directory: "Resource_Home_Page",
        });
      }

      // Fetch all resources
      const { data: linkData, error: linkDataError } = await supabase
        .from('link')
        .select('*')
        .eq('tag', 'Resource');

      if (linkDataError) console.log("error", linkDataError);

      let resources: Resource[] = linkData?.map((item: any) => ({
        id: item.id,
        title: item.name,
        description: item.description,
        body: item.description,
        tag: item.tag,
        cover_image: item.cover_image || "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
        tag_id: item.tag_id,
        link: item.link,
        parent_directory: item.parent_directory,
      })) ?? [];

      // Combine default resources with fetched resources
      resources = [...defaultResources, ...resources];

      // Filter resources based on user role
      if (userRole === "Agent") {
        resources = resources.filter((item: Resource) => 
          item.parent_directory === "Agent Resources" || 
          item.parent_directory === "_Everyone" ||
          item.parent_directory === "Resource_Home_Page"
        );
      }

      // Organize resources into a hierarchical structure
      const organizedResources = resources.reduce((acc: Resource[], item: Resource) => {
        if (!item.parent_directory || item.parent_directory === "Resource_Home_Page") {
          acc.push(item);
        } else {
          const parentIndex = acc.findIndex((resource: Resource) => resource.title === item.parent_directory);
          if (parentIndex !== -1) {
            if (!acc[parentIndex].children) {
              acc[parentIndex].children = [];
            }
            acc[parentIndex].children!.push(item);
          } else {
            acc.push({
              id: Number(`parent_${item.parent_directory}`),
              title: item.parent_directory,
              description: `Resources for ${item.parent_directory}`,
              body: "",
              tag: "Resource",
              cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
              tag_id: 0,
              parent_directory: "",
              children: [item]
            });
          }
        }
        return acc;
      }, []);

      setPageData(organizedResources);
      setUserRole(userRole);
      setLoading(false); // Set loading to false when done fetching
    };

    fetchData();
  }, []);

  const getUserPerms = async (userId: string | undefined) => {
    if (!userId) return null;

    const { data: profileRoleData, error: profileRoleDataError } = await supabase
      .from('profile_role')
      .select('*')
      .eq('user_id', userId)
      .single();

    if (profileRoleDataError) {
      console.log("error", profileRoleDataError);
      return null;
    }

    const { data: roledata, error: roledataError } = await supabase
      .from('role')
      .select('*')
      .eq('id', profileRoleData?.role_id)
      .single();

    if (roledataError) {
      console.log("error", roledataError);
      return null;
    }

    return roledata?.role_name;
  };

  const fetchResults = async (table: string, column: string, searchTerm: string) => {
    return await supabase
      .from(table)
      .select("*")
      .textSearch(column, searchTerm || "", { type: "websearch" });
  };
  
  const processResults = (results: any, key: string, maxLength: number) => {
    if (results?.data) {
      results.data.forEach((item: any) => {
        item[key] = item[key].substring(0, maxLength);
      });
    }
  };

  const addTableName = (results: any, tableName: string) => {
    if (results?.data) {
      results.data.forEach((item: any) => (item.table_name = tableName));
    }
  };
  
  const search = async () => {
    console.log("Search term: ", searchTerm);
  
    try {
      setLoading(true);

      const [titleResultsPage, bodyResultsPage, titleResultsAnnouncements, descriptionResultsAnnouncements, bodyResultsAnnouncements, linkResultsTitle, linkResultsDescription] = await Promise.all([
        fetchResults("google_docs", "name", searchTerm ?? ""),
        fetchResults("google_docs", "content", searchTerm ?? ""),
        fetchResults("announcement", "title", searchTerm ?? ""),
        fetchResults("announcement", "description", searchTerm ?? ""),
        fetchResults("announcement", "body", searchTerm ?? ""),
        fetchResults("link", "name", searchTerm ?? ""),
        fetchResults("link", "description", searchTerm ?? "")
      ]);

      processResults(titleResultsPage, "name", 25);
      processResults(bodyResultsPage, "content", 30);
      processResults(titleResultsAnnouncements, "title", 25);
      processResults(descriptionResultsAnnouncements, "description", 30);
      processResults(bodyResultsAnnouncements, "body", 30);
      processResults(linkResultsTitle, "name", 25);
      processResults(linkResultsDescription, "description", 30);
  
      addTableName(titleResultsPage, "page");
      addTableName(bodyResultsPage, "page");
      addTableName(titleResultsAnnouncements, "announcement");
      addTableName(descriptionResultsAnnouncements, "announcement");
      addTableName(bodyResultsAnnouncements, "announcement");
      addTableName(linkResultsTitle, "link");
      addTableName(linkResultsDescription, "link");
  
      let results = [
        ...(titleResultsPage?.data ?? []),
        ...(bodyResultsPage?.data ?? []),
        ...(titleResultsAnnouncements?.data ?? []),
        ...(descriptionResultsAnnouncements?.data ?? []),
        ...(bodyResultsAnnouncements?.data ?? []),
        ...(linkResultsTitle?.data ?? []),
        ...(linkResultsDescription?.data ?? []),
      ];
  
      if (userRole === "Agent") {
        console.log("Agent");
        results = results.filter((item: any) => 
          item.topLevelParentName === "Agent Resources" || 
          item.topLevelParentName === "_Everyone"
        );
      }
  
      results = results.map((item) => {
        let formattedItem = { ...item };
  
        if (item.table_name === "page") {
          formattedItem = {
            ...formattedItem,
            title: item.name.substring(0, 25),
            body: item.content,
            description: item.content.substring(0, 30),
            cover_image: "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
            tag: "Resource",
            link: item.link
          };
        } else if (item.table_name === "link") {
          formattedItem = {
            ...formattedItem,
            title: item.name,
            description: item.description,
            cover_image: item.cover_image || "https://media.discordapp.net/attachments/1134099496020754533/1201899876619911279/Copy_of_COMMUNITY_LOGO_WhiteLetter_FINAL.jpg?ex=65cb7f7c&is=65b90a7c&hm=5b14949c7e3584a33d01dd7280c4efd19e627b8c305511f27cac28e96376bc92&=&format=webp&width=1251&height=703",
            tag: "Resource",
          };
        } else {
          formattedItem.tag = "Announcement";
        }
        
        return formattedItem;
      });
  
      results = results.filter(
        (item, index) => results.findIndex((x) => x.id === item.id) === index
      );
  
      console.log("Search results: ", results);
      setPageData(results);
      setActivePageData([]);
  
      setLoading(false);
  
      return results;
    } catch (error) {
      console.error("Search failed: ", error);
      setLoading(false);
      return [];
    }
  };

  const handleClick = () => {
    window.location.href = "/page";
  };

  const handleCardClick = (item: Resource) => {
    if (item.children) {
      setActivePageData(item.children as Resource[]);
    } else if (item.link) {
      window.open(item.link, "_blank");
    }
  };

  return (
    <div className="flex w-full h-screen overflow-hidden text-white">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="page-bg-header xl:p-12 lg:p-8 md:p-7 sm:p-6 p-3 flex sm:flex-row flex-col leading-[150%] justify-between items-center">
            <p className="2xl:text-5xl xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-3xl sm:m-0 m-4 text-white cursor-pointer" onClick={handleClick}>
              Resource Pages
            </p>
            <div className="relative md:w-2/3 sm:w-7/12 w-full bg-white flex rounded-xl">
              <input
                className="flex-1 leading-[150%] p-4 rounded-lg w-fit bg-white focus:outline-none text-black border-none text-base"
                placeholder="Search Resources"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    search();
                  }
                }}
              />
              <div
                onClick={search}
                className="absolute top-0 -right-1 shadow max-xs:hidden"
              >
                <Search />
              </div>
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center h-[calc(100vh-200px)]">
              <LoadingSpinner />
            </div>
          ) : (
            <>
              <CardGrid
                data={activePageData.length > 0 ? activePageData : pageData}
                onCardClick={handleCardClick as (item: CardProps) => void}
              />
              {pageData.length === 0 && searchTerm && (
                <div className="mt-10 ml-4 text-center">
                  No resource results found for "{searchTerm}"
                </div>
              )}
            </>
          )}
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default ResourcesPageHome;
