import backGroundLinesLoginPage from './backgroundLinesLoginPage.svg'
import bottomCornerLoginPage from './bottomCornerLoginPage.svg'
import communityNameLogo from './communityNameLogo.svg'
import loginPageBackgroundLines from './loginPageBackgroundLines.svg'
import topCornerLoginPage from './topCornerLoginPage.svg'
import backGroundLinesLoginPage2 from './backgroundLinesLoginPage2.png'
import phone from './phone.svg'
import circle from './circle.svg'
import circleColor from './circleColor.png'
import ellipse from './ellipse-14@2x.png'
import test from './test.svg'
import communityLogo from './communityLogo.svg'
import rectangle23 from './rectangle-23@2x.png'
import magnifyingglass from './magnifyingglass-1.svg'
import rectangle26_2x from './rectangle-26@2x.png'
import next_2 from './next-2.svg'
import line_1 from './line-1.svg'
import rectangle232xredOranage from './rectangle-23@2xredOranage.png'
import rectangle542x from './rectangle542x.png'
import dashboard from './dashboard.svg'
import robot from './robot.svg'
import folder from './folder.svg'
import rectangle from './Rectangle.svg'
import marketing from './marketing.svg'
import training from './training.svg'
import users from './Users.svg'
import chevron_right from './chevron-right.svg'
import chatbot from './chatbot.svg'
import MarketingPages from './MarketingPages.svg'
import TrainingPages from './TrainingPages.svg'
import ResourcePages from './ResourcePages.svg'
import mini_logo from './mini-logo.svg'
import calendar from './calendar.svg'
import pin from './pin.svg'
import feedback from './feedback.svg'
import communityLogo1 from './communityLogo.jpg'
import CommunityLogoNoBackground from './CommunityLogoNoBackground.png'


export {
    backGroundLinesLoginPage,
    bottomCornerLoginPage,
    communityNameLogo,
    loginPageBackgroundLines,
    topCornerLoginPage,
    backGroundLinesLoginPage2,
    phone,
    circle,
    circleColor,
    ellipse,
    test,
    communityLogo,
    rectangle23,
    magnifyingglass,
    rectangle26_2x,
    next_2,
    line_1,
    rectangle232xredOranage,
    rectangle542x,
    dashboard,
    robot,
    folder,
    rectangle,
    marketing,
    training,
    users,
    chevron_right,
    chatbot,
    MarketingPages,
    TrainingPages,
    ResourcePages,
    mini_logo,
    calendar,
    pin,
    feedback,
    communityLogo1,
    CommunityLogoNoBackground
}