// @ts-nocheck
import React, { useState, useEffect } from "react";
import MainEditor from "../../../editor";
import Sidebar from "../../../components/Sidebar/Sidebar";
import supabaseConnect from "../../../utils/supabaseConnect";
import { on } from "events";
import { useParams } from "react-router-dom";
import MainLayoutWrapper from "../../../components/layout/MainLayoutWrapper";

const EditPage = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const supabase = supabaseConnect();
  const [options, setOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [body, setBody] = useState("");
  const [coverImage, setCoverImage] = useState("");
  const [editor, setEditor] = useState(null);
  const clearContent = () => {
    setEditor("");
    setTitle("");
    setDescription("");
    setBody("");
  };
  let rolesId = [];
  const [adminRole, setAdminRole] = useState(false);
  const [selectedTypeOptions, setSelectedTypeOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const pageId = useParams();
  const [pageType, setPageType] = useState([]);

  // add auth logic that you have to be admin to view

  const handleSubmit = async () => {
    // get text from inputs and editor and set to state
    const title = document.getElementById("grid-title") as HTMLInputElement;
    const description = document.getElementById(
      "grid-description"
    ) as HTMLInputElement;
    const whoIsThisFor = options;

    const titleValue = title.value;
    const descriptionValue = description.value;
    const editorValue = editor;

    // submit resource to db
    await submitResource(titleValue, descriptionValue, editorValue);
  };

  // check if user is admin
  const checkAdmin = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user.id;

    const { data, error } = await supabase
      .from("profile_role")
      .select("*")
      .eq("user_id", userId);

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      console.log(data);
      if (data.length <= 0) {
        return (window.location.href = "/");
      }
    }
  };

  const getAllImages = async () => {
    const { data, error } = await supabase.storage.from("media").list();

    if (error) alert(error?.message);

    console.log(data);

    return data;
  };

  const getRole = async (roles) => {
    for (let i = 0; i < roles.length; i++) {
      let roleName = roles[i];
      const { data, error } = await supabase
        .from("role")
        .select("*")
        .eq("role_name", roleName);

      rolesId.push(data[0]);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(rolesId);
      }
    }
    return rolesId;
  };

  useEffect(() => {
    const fetchOptions = async () => {
      const { data, error } = await supabase.from("role").select();

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        // filter out the admin role
        const newData = data.filter((role) => role.role_name !== "Admin");
        setOptions(newData);
      }
    };
    const fetchTypeOptions = async () => {
      const { data, error } = await supabase.from("tag").select();

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        // filter out the admin role
        console.log(data);
        setTypeOptions(data);
      }
    };
    const fetchData = async () => {
      const { data, error } = await supabase
        .from("page")
        .select("*")
        .eq("id", pageId.id);

      if (error) {
        console.error("Error fetching options: ", error);
      } else {
        console.log(data);
        setTitle(data[0].title);
        setDescription(data[0].description);
        setEditor(data[0].body);
        setPageType(data[0].tag);
        setCoverImage(data[0].cover_image);
        setSelectedTypeOptions(data[0].tag);
      }
    };

    const pageRoleFetch = async () => {
      const { data, error } = await supabase
        .from("page_role")
        .select("*")
        .eq("page_id", pageId.id);

      if (error) {
        console.error("Error fetching options: ", error);
      }

      const { data: role, error: roleError } = await supabase
        .from("role")
        .select("*")
        .eq("id", data[0].role_id);

      if (error) {
        console.error("Error fetching options: ", error);
      }

      setSelectedOptions([role[0].role_name]);
    };

    const fetches = async () => {
      await fetchData();
      await fetchTypeOptions();
      await checkAdmin();
      await fetchOptions();
      await pageRoleFetch();
    };
    fetches();
  }, []);

  const handleUpload = async (e) => {
    const file = e.target.files[0];

    const filename = `${Math.floor(Math.random() * 1000000000)}`;

    if (file) {
      const { data, error } = await supabase.storage
        .from("media")
        .upload(`${filename}.${file.name}`, file);

      const { data: publicUrl, error: publicUrlError } = await supabase.storage
        .from("media")
        .getPublicUrl(`${filename}.${file.name}`);

      if (error) {
        console.log(error);
      }
      setCoverImage(publicUrl.publicUrl);

      const images = getAllImages();

      return images;
    }
    return "no file";
  };

  const submitResource = async (titleValue, descriptionVaue, editorValue) => {
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const userId = user.id;

    // error handling
    if (
      !titleValue ||
      !descriptionVaue ||
      !editorValue ||
      !coverImage ||
      selectedOptions.length < 1 ||
      selectedTypeOptions.length < 1
    ) {
      return alert("Please fill out all fields");
    }

    const { data: tag, error: tagError } = await supabase
      .from("tag")
      .select("*")
      .eq("tag_name", selectedTypeOptions);

    if (tagError) {
      console.error("Error fetching options: ", tagError);
    }

    const { data, error } = await supabase
      .from("page")
      .update({
        title: titleValue,
        description: descriptionVaue,
        body: editorValue,
        cover_image: coverImage,
        user_id: userId,
        tag: tag[0].tag_name,
      })
      .eq("id", pageId.id)
      .select("*");

    if (error) {
      console.error("Error fetching options: ", error);
    } else {
      const roleId = await getRole(selectedOptions);
      console.log(roleId);

      for (let i = 0; i < roleId.length; i++) {
        const newRoleId = roleId[i].id;
        const pageId = data[0].id;
        const { data: page_role, error: page_role_error } = await supabase
          .from("page_role")
          .update({
            page_id: pageId,
            role_id: newRoleId,
          })
          .eq("page_id", pageId)
          .select("*");

        if (page_role_error) {
          console.error("Error fetching options: ", page_role_error);
        } else {
          console.log(page_role);
        }
      }

      rolesId = [];
      window.location.href = `/page/${data[0].id}`;
    }
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedOptions((prev) => [...prev, event.target.value]);
    } else {
      setSelectedOptions((prev) =>
        prev.filter((option) => option !== event.target.value)
      );
    }
  };

  // todo: pulling users from db and displaying them in a list to select from only admins are displayed

  return (
    <div className="flex w-full h-screen overflow-hidden text-left text-5xl  font-inter">
      <Sidebar />
      <MainLayoutWrapper>
        <div className="p-5">
          <div className="min-h-screen py-2 text-white">
            <div className="flex justify-between items-center pl-[1rem] pr-[1rem] border-solid border-lightGray border-[.1px]">
              <h1 className="text-[1.2rem] font-bold">Edit Resource</h1>
              <button
                onClick={handleSubmit}
                className="border px-4 py-2 cursor-pointer text-white border-none bg-lightBlue rounded-md"
              >
                Publish Changes
              </button>
            </div>
            <form className="border-solid border-lightGray border-[.1px] p-[1rem]">
              <div className="flex flex-wrap mb-6 ">
                <div className="w-full mb-6 md:mb-0">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-title"
                  >
                    Title
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none rounded-md border-solid border-[.2px]"
                    id="grid-title"
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Description
                  </label>
                  <input
                    className="appearance-none block w-full bg-gray-900 text-white border border-gray-900 rounded py-3 mb-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></input>
                </div>
              </div>

              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-description"
                  >
                    Cover Image
                  </label>
                  {/* Display the current image if available */}
                  {coverImage && (
                    <div className="mb-3">
                      <img
                        src={coverImage}
                        alt="Current Cover"
                        className="w-[15rem]"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleUpload(e)}
                    className="appearance-none block w-full bg-gray text-white border border-gray rounded py-3 leading-tight focus:border-gray focus:outline-none focus:outline-[.1px] rounded-md border-solid border-[.1px] border-none resize-none"
                    id="grid-description"
                  />
                  {/* Optionally, add a note about keeping the current image */}
                  {coverImage && (
                    <p className="text-xs text-gray-500">
                      If you don't choose a new image, the current image will be
                      kept.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  {/* create a drop down that you can make multiple selections */}
                  <div className="w-full">
                    <label
                      className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-description"
                    >
                      Who is this resource for?
                    </label>
                    <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
                      {options.map((option, index) => (
                        <label key={index} className="p-2">
                          <input
                            type="checkbox"
                            checked={selectedOptions.includes(option.role_name)}
                            value={option.role_name}
                            onChange={handleCheckboxChange}
                            className="mr-2"
                          />
                          {option.role_name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  {/* create a drop down that you can make multiple selections */}
                  <div className="w-full">
                    <label
                      className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                      htmlFor="grid-description"
                    >
                      What type of resource is this?
                    </label>
                    <div className="flex flex-row space-x-4 p-2 bg-gray border-lightGray rounded">
                      {typeOptions?.map((option, index) => (
                        <label key={index} className="p-2">
                          <input
                            type="radio"
                            value={option.tag_name}
                            checked={pageType === option.tag_name}
                            onChange={(e) => {
                              setSelectedTypeOptions(e.target.value);
                            }}
                            name="tag"
                            className="mr-2"
                          />
                          {option.tag_name}
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-6 w-full">
                <div className="w-full">
                  <label
                    className="block uppercase tracking-wide text-white text-xs font-bold mb-2"
                    htmlFor="grid-content"
                  >
                    Content
                  </label>
                  <div className="">
                    <MainEditor editor={editor} setEditor={setEditor} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </MainLayoutWrapper>
    </div>
  );
};

export default EditPage;
