import React, { useState, useEffect, useRef } from "react";
import { magnifyingglass } from "../../../assets";
import { FaCaretDown } from 'react-icons/fa';
import Sidebar from "../../../components/Sidebar/Sidebar"


const AdminDashboard = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    // todo: change to db data connection
    const data = [
        {
            title: "Title 1",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 1',
        },
        {
            title: "Title 2",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 2',
        },
        {
            title: "Title 3",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 3',
        },
        {
            title: "Title 4",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 4',
        },
        {
            title: "Title 5",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 5',
        },
        {
            title: "Title 6",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 6',
        },
        {
            title: "Title 7",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 7',
        },
        {
            title: "Title 8",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 8',
        },
        {
            title: "Title 9",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 9',
        },
        {
            title: "Title 10",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 10',
        },
        {
            title: "Title 11",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 11',
        },
        {
            title: "Title 12",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 12',
        },
        {
            title: "Title 13",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 13',
        },
        {
            title: "Title 14",
            dateCreated: "01/01/2021",
            catagory: 'Catagory 14',
        },
    ]

    const pageOnClick = (e: any) => {
        console.log(e);
    }

    // TODO: Add search functionality in the form of a filter
    // TODO: create db connection and functionality

    return (
        <>
            {/* todo: will need to add sidebar child that makes a right active effect depending on the page I am on */}
            <div className='fixed top-0 left-0 z-10 h-full text-white'>
                <Sidebar />
            </div>
            <div className="ml-[10rem]">
                <div className="flex flex-col md:flex-row justify-end items-center p-[2rem]">
                    <div className="flex flex-row justify-between items-center mr-[2.5rem]">
                        <div className="relative w-[37.938rem] h-[3.125rem] text-base bg-black border rounded-lg mr-2">
                            <div className="absolute top-[0px] left-[0px] rounded-xl bg-black1 w-[37.938rem] h-[3.125rem]" />
                            <input
                                className="absolute top-[0.78rem] left-[3rem] leading-[150%] inline-block w-[33rem] bg-black focus:outline-none text-white border-none h-[1.5rem] text-[1rem]"
                            />
                            <img
                                className="absolute top-[15px] left-[15px] w-5 h-5 overflow-hidden"
                                alt=""
                                src={magnifyingglass}
                            />
                        </div>
                        <div className="relative" ref={dropdownRef}>
                            <button onClick={toggleDropdown} className="bg-blue rounded-md p-2 m-2 cursor-pointer border-gray flex items-center w-[4.5rem] items-center justify-center">
                                Create <FaCaretDown />
                            </button>
                            {dropdownOpen && (
                                <div className="absolute right-0 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 mr-2">
                                    <div className="py-1 flex flex-col" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                        <a href="#" className="block pl-5 px-4 py-2 text-sm text-gray hover:bg-gray-100 hover:text-gray no-underline border-b border-lightGray active:bg-gray active:text-white hover:bg-gray5 hover:text-white " role="menuitem">Announcement</a>
                                        <a href="#" className="block pl-5 px-4 py-2 text-sm text-gray hover:bg-gray-100 hover:text-gray no-underline active:bg-gray active:text-white hover:bg-gray5 hover:text-white" role="menuitem">Resource</a>
                                        <a href="#" className="block pl-5 px-4 py-2 text-sm text-gray hover:bg-gray-100 hover:text-gray no-underline active:bg-gray active:text-white hover:bg-gray5 hover:text-white" role="menuitem">Event</a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="pr-[5rem] pl-[10rem]">
                    <div className="flex flex-row justify-between items-center bg-black">
                        <div className="pl-10 text-center">
                            <h3 className="text-white">Title</h3>
                        </div>
                        <div className="w-1/2 text-right mr-[2rem]">
                            <h3 className="text-white">Date Created</h3>
                        </div>
                        <div className="w-1/6 ml-[4rem]">
                            <h3 className="text-white">Catagory</h3>
                        </div>
                    </div>
                    {data.map((item, index) => (
                        <div key={index} className="border-solid border-[.1px] border-lightGray flex flex-row justify-between items-center cursor-pointer hover:bg-gray5 active:bg-gray" onClick={(e) => pageOnClick(e)}>
                            <div className="pl-10 text-center">
                                <p className="text-white">{item.title}</p>
                            </div>
                            <div className="w-1/2 text-right mr-[6.5rem]">
                                <p className="text-white">{item.dateCreated}</p>
                            </div>
                            <div className="w-1/6 ml-[4rem]">
                                <p className="text-white">{item.catagory}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default AdminDashboard;