import { useCallback, useEffect, useState, useRef } from "react";
import {
  // ellipse,
  communityLogo,
  dashboard,
  robot,
  folder,
  training,
  marketing,
  users,
  chevron_right,
  mini_logo,
  feedback,
  CommunityLogoNoBackground,
} from "../../assets";
import supabaseConnect from "../../utils/supabaseConnect";

const Sidebar = () => {
  const [user, setUser] = useState(null) as any;
  const [profile, setProfile] = useState(null) as any;
  const supabase = supabaseConnect();
  const [link, setLink] = useState("");
  const [userAdmin, setUserAdmin] = useState(false);

  const [hamburgerState, setHamburgerState] = useState(true);

  console.log("hamburgerState", hamburgerState);

  useEffect(() => {
    setHamburgerState(hamburgerState);
  }, [hamburgerState]);

  let sideNavMode = localStorage.getItem("realCommunitySideNav");
  const [sideNavState, setSideNavState] = useState<string | null>(sideNavMode);

  useEffect(() => {
    const getUser = async () => {
      const { data: user, error } = await supabase.auth.getSession();
      if (error) return console.log(error);
      if (!user) {
        return (window.location.href = "/login");
      }
      setUser(user);
      return user;
    };
    const getProfile = async (id: string) => {
      const cachedProfile = localStorage.getItem(`profile_${id}`);
      if (cachedProfile) {
        const parsedProfile = JSON.parse(cachedProfile);
        setProfile(parsedProfile);
        return parsedProfile;
      }
      const { data: profile, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", id)
        .single();
      if (error) return console.log(error);
      localStorage.setItem(`profile_${id}`, JSON.stringify(profile));
      setProfile(profile);
      return profile;
    };
    const getUsersProfiles = async () => {
      const user = await getUser();
      if (typeof user === "object" && user !== null && user.session) {
        const userId = user.session.user.id;
        let profile = await getProfile(userId);

        if (!profile) {
          // Retry fetching the profile if it fails the first time
          profile = await getProfile(userId);
        }
      }
    };
    const getUserAdmin = async () => {
      const user = await supabase.auth.getSession();
      const { data, error } = await supabase
        .from("profile_role")
        .select("*")
        .eq("user_id", user.data.session?.user.id)
        .limit(1);
      if (error) console.log("error", error);
      if (data?.[0].role_id === 1) setUserAdmin(true);
    };
    getUserAdmin();
    getUsersProfiles();
    switch (window.location.pathname) {
      case "/":
        setLink("Dashboard");
        break;
      case "/chatbot":
        setLink("Community Chatbot");
        break;
      case "/page":
        setLink("Resource Pages");
        break;
      case "/marketing":
        setLink("Marketing Pages");
        break;
      case "/training":
        setLink("Training Pages");
        break;
      case "/users":
        setLink("Users");
        break;
      case "/feedback":
        setLink("Feedback");
        break;
    }
  }, []);

  const handleLinkClick = (e: any) => {
    const textContent = e.target.textContent;
    setLink(textContent);

    switch (textContent) {
      case "Dashboard":
        window.location.href = "/";
        break;
      case "Community Chatbot":
        // alert("This feature is not yet available");
        window.location.href = "/community-chatbot";
        break;
      case "Resources":
        // Handle Resource Pages case
        window.location.href = "/page";
        break;
      case "Marketing Center":
        // Handle Marketing Pages case
        window.location.href = "/marketing";
        // redircet to https://marketing.realcommunity.com/ as a new tab
        // window.open("https://marketing.realcommunity.com/", "_blank");
        break;
      case "Training Resources":
        // Handle Training Pages case
        window.location.href = "/training";
        break;
      case "Users":
        // Handle Admin Panel case
        window.location.href = "/users";
        break;
      case "Feedback":
        // Handle Feedback case
        window.open("https://forms.gle/eqmXBTyieZXgvYyB9", "_blank");
        break;
      default:
        // Handle default case
        window.location.href = "/dashboard";
        break;
    }
  };

  const onFrameContainer5Click = useCallback(() => {
    // Please sync "Dashboard page_Default states_1920" to the project
  }, []);

  const onFrameContainer8Click = useCallback(() => {
    // Please sync "Dashboard page_Default states_1920" to the project
  }, []);

  const onFrameContainer10Click = useCallback(() => {
    // Please sync "Dashboard page_Default states_1920" to the project
  }, []);

  const onFrameContainer12Click = useCallback(() => {
    // Please sync "Dashboard page_Default states_1920" to the project
  }, []);

  const onRectangle3Click = useCallback(() => {
    // Please sync "Dashboard page_Default states_1920" to the project
  }, []);

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) return console.log(error);
    return (window.location.href = "/login");
  };

  const handleProfileClick = () => {
    window.location.href = "/profile";
  };

  useEffect(() => {
    console.log("sideNavState changed:", sideNavState);
    let mainLayoutEle = document.querySelector("#main-layout");
    let miniSideNavEle = document.querySelector("#mini-inner-sidenav");
    let fullSideNavEle = document.querySelector("#full-inner-sidenav");
    let chevronEle = document.querySelector("#chevron");

    if (sideNavState === "collapsed") {
      console.log("Applying collapsed classes");
      mainLayoutEle?.classList.add("full-main-layout");

      chevronEle?.classList.add("rotate-180");

      miniSideNavEle?.classList.add("flex");
      miniSideNavEle?.classList.remove("hidden");

      fullSideNavEle?.classList.add("hidden");
      fullSideNavEle?.classList.remove("flex");
    } else {
      console.log("Applying full classes");
      mainLayoutEle?.classList.remove("full-main-layout");

      chevronEle?.classList.remove("rotate-180");

      miniSideNavEle?.classList.add("hidden");
      miniSideNavEle?.classList.remove("flex");

      fullSideNavEle?.classList.add("flex");
      fullSideNavEle?.classList.remove("hidden");
    }
  }, [sideNavState]);

  const toggleSideNav = () => {
    let mainLayoutEle = document.querySelector("#main-layout");
    let miniSideNavEle = document.querySelector("#mini-inner-sidenav");
    let fullSideNavEle = document.querySelector("#full-inner-sidenav");
    let chevronEle = document.querySelector("#chevron");

    console.log("Before toggle:", sideNavState);

    if (sideNavState === "full") {
      localStorage.setItem("realCommunitySideNav", "collapsed");
      setSideNavState("collapsed");
      setHamburgerState(true);
    } else {
      localStorage.setItem("realCommunitySideNav", "full");
      setSideNavState("full");
      setHamburgerState(true);
    }
    console.log("After toggle:", sideNavState);
  };
  //  Hook that disappears sidebar clicks outside of the passed ref

  const useOutsideClicker = (ref: any) => {
    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setHamburgerState(true);
          console.log("hamburgerState", hamburgerState);
        }
      };
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideClicker(wrapperRef);

  const handleChevron = () => {
    setHamburgerState(!hamburgerState);
    setSideNavState("full");
  };

  return (
    <div
      className={`${hamburgerState === true ? "relative " : "absolute z-20 max-sm:w-full"} `}
    >
      <div
        id="mobile-header"
        className="absolute top-0 flex bg-white font-black text-black h-11 w-screen justify-center items-center font-black text-lg  lg:hidden z-20 border-4 border-blue-500"
      >
        <div
          className="HAMBURGER-ICON space-y-2 absolute top-3 left-3 lg:hidden cursor-pointer z-20"
          onClick={() => handleChevron()}
        >
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
          <span className="block h-0.5 w-6 animate-pulse bg-gray-600"></span>
        </div>
        <img
          src={CommunityLogoNoBackground}
          alt="community logo"
          className="w-[11rem] h-[2rem]"
        />
      </div>

      <aside
        className={`relative ${
          hamburgerState === true
            ? "lg:flex hidden"
            : "flex absolute top-15 left-15 z-30"
        } flex-row justify-between text-center text-xs h-screen bg-black text-white`}
        ref={wrapperRef}
      >
        {/* todo: This is the user profile pulled from the db. Will need a rework and functionality */}

        <div
          onClick={toggleSideNav}
          role="button"
          className="absolute top-[50px] right-0 sm:right-[-17px] bg-[#099aad] sm:rounded-tr-[4px] sm:rounded-br-[4px] max-sm:rounded-tl-[4px] max-sm:rounded-bl-[4px] flex px-[1px] py-1 cursor-pointer"
        >
          <img id="chevron" src={chevron_right} width="15" height="15" alt="" />
        </div>

        <div
          id="mini-inner-sidenav"
          className={`overflow-y-auto ${
            hamburgerState !== false ? "max-lg:flex hidden" : "hidden"
          } flex-col flex-1 w-16 transition-all`}
        >
          <div className="w-full flex flex-col flex-1 items-start justify-start gap-8 text-left text-base">
            <div className="bg-gray-800 w-full flex flex-col items-center justify-center box-border py-3 ">
              <img
                src={mini_logo}
                alt="mini logo for the collapsed sidenav"
                width="15"
              />
            </div>
            {/* bug for extending sidebar past where it should is somewhere below here: */}
            <div className="w-full  flex flex-row items-start justify-start py-0  box-border">
              <div className="flex-1  flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-center gap-[24px]">
                  <div className="self-stretch flex flex-row items-center justify-center tooltip">
                    <a
                      href="/"
                      onClick={(e) => handleLinkClick(e)}
                      title="Dashboard"
                      className={`${
                        link === "Dashboard" || "" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={dashboard}
                      />
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Community Chatbot" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10 tooltip`}
                    onClick={onFrameContainer5Click}
                  >
                    <a
                      href="/"
                      onClick={() => alert("This feature is not yet available")}
                      className="flex flex-row items-center justify-center gap-[8px]"
                      title="Community Chatbot"
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={robot}
                      />
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Resource Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    onClick={onFrameContainer8Click}
                  >
                    <a
                      href="/page"
                      title="Resources"
                      className="self-stretch flex flex-row items-center justify-center"
                    >
                      <div className="flex flex-row items-center justify-start gap-[8px]">
                        <img
                          className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                          alt=""
                          src={folder}
                        />
                      </div>
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Marketing Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    onClick={onFrameContainer10Click}
                  >
                    <a
                      title="Marketing Center"
                      href="/marketing"
                      className="flex flex-row items-center justify-center gap-[8px]"
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={marketing}
                      />
                    </a>
                  </div>
                  <div
                    className={`${
                      link === "Training Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                    onClick={onFrameContainer12Click}
                  >
                    <a
                      title="Training"
                      href="/training"
                      className="flex flex-row items-center justify-center gap-[8px]"
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={training}
                      />
                    </a>
                  </div>
                  {/* todo: build out logic that only shows this if someone has admin role */}
                  {userAdmin && (
                    <div
                      className={`${
                        link === "Users" ? "" : "opacity-[0.5]"
                      } self-stretch flex flex-row items-center justify-center cursor-pointer z-10`}
                      onClick={onFrameContainer12Click}
                    >
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                          alt=""
                          src={users}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            {profile && (
              <div className=" flex flex-row items-center justify-start gap-[.8rem] text-left text-base">
                <img
                  className="rounded-[50%] w-10 h-10 object-cover"
                  alt=""
                  src={
                    profile?.profile_image_url ||
                    "https://via.placeholder.com/150"
                  }
                />
                <div className="overflow-hidden">
                  <p className="m-0 font-sm word-clip">
                    {profile.first_name && profile.last_name
                      ? `${profile.first_name} ${profile.last_name}`
                      : ""}
                  </p>
                  <p className="m-0 text-sm leading-[16px] word-clip">
                    {profile?.email}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          id="full-inner-sidenav"
          className={` ${
            hamburgerState === true ? "max-lg:hidden flex" : "flex"
          } overflow-y-auto flex-col flex-1 transition-all`}
        >
          <div className="w-full flex flex-col flex-1 items-start justify-start gap-[32px] text-left text-base">
            <div className="bg-gray w-full flex flex-col items-center justify-center box-border">
              <img
                className="relative w-[180px] h-[4rem] overflow-hidden shrink-0 cursor-pointer z-10"
                alt=""
                src={communityLogo}
                onClick={() => (window.location.href = "/")}
              />
            </div>
            {/* bug for extending sidebar past where it should is somewhere below here: */}
            <div className="w-full  flex flex-row items-start justify-start py-0 pr-5 pl-[30px] box-border">
              <div className="flex-1  flex flex-col items-start justify-start">
                <div className="self-stretch flex flex-col items-start justify-center gap-[24px]">
                  <div className="self-stretch flex flex-row items-center justify-between">
                    <div
                      className={`${
                        link === "Dashboard" || "" ? "" : "opacity-[0.5]"
                      } gap-[8px] self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    >
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={dashboard}
                      />
                      <div
                        onClick={(e) => handleLinkClick(e)}
                        className="relative"
                      >
                        Dashboard
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      link === "Community Chatbot" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    onClick={onFrameContainer5Click}
                  >
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={robot}
                      />
                      <div
                        onClick={(e) => handleLinkClick(e)}
                        className="relative"
                      >
                        Community Chatbot
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      link === "Resource Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    onClick={onFrameContainer8Click}
                  >
                    <div className="self-stretch flex flex-row items-center justify-between">
                      <div className="flex flex-row items-center justify-start gap-[8px]">
                        <img
                          className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                          alt=""
                          src={folder}
                        />
                        <div
                          onClick={(e) => handleLinkClick(e)}
                          className="relative font-medium inline-block w-[123px] h-[15px] shrink-0 -mt-1"
                        >
                          Resources
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      link === "Marketing Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    onClick={onFrameContainer10Click}
                  >
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={marketing}
                      />
                      <div
                        onClick={(e) => handleLinkClick(e)}
                        className="relative"
                      >
                        Marketing Center
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      link === "Training Pages" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    onClick={onFrameContainer12Click}
                  >
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={training}
                      />
                      <div
                        onClick={(e) => handleLinkClick(e)}
                        className="relative"
                      >
                        Training Resources
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      link === "Feedback" ? "" : "opacity-[0.5]"
                    } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                    onClick={onFrameContainer12Click}
                  >
                    <div className="flex flex-row items-center justify-center gap-[8px]">
                      <img
                        className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                        alt=""
                        src={feedback}
                      />
                      <div
                        onClick={(e) => handleLinkClick(e)}
                        className="relative"
                      >
                        Feedback
                      </div>
                    </div>
                  </div>
                  {/* todo: build out logic that only shows this if someone has admin role */}
                  {userAdmin && (
                    <div
                      className={`${
                        link === "Users" ? "" : "opacity-[0.5]"
                      } self-stretch flex flex-row items-center justify-between cursor-pointer z-10`}
                      onClick={onFrameContainer12Click}
                    >
                      <div className="flex flex-row items-center justify-center gap-[8px]">
                        <img
                          className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                          alt=""
                          src={users}
                        />
                        <div
                          onClick={(e) => handleLinkClick(e)}
                          className="relative"
                        >
                          Users
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            {profile && (
              <div className=" flex flex-row items-center justify-start gap-[.8rem] text-left text-base">
                <img
                  className="rounded-[50%] w-10 h-10 object-cover"
                  alt=""
                  src={
                    profile?.profile_image_url ||
                    "https://via.placeholder.com/150"
                  }
                />
                <div className="overflow-hidden">
                  <p className="m-0 font-sm word-clip">
                    {profile.first_name && profile.last_name
                      ? `${profile.first_name} ${profile.last_name}`
                      : ""}
                  </p>
                  <p className="m-0 text-sm leading-[16px] word-clip">
                    {profile?.email}
                  </p>
                </div>
              </div>
            )}

            <div className=" w-full my-4 ">
              <p
                style={{
                  border: "1px solid white",
                }}
                role="button"
                onClick={handleProfileClick}
                className=" w-auto font-medium cursor-pointer p-[0.8rem]  bg-black rounded-md "
              >
                My profile
              </p>
            </div>
            <div className="">
              <p
                onClick={handleSignOut}
                className="w-auto font-medium cursor-pointer p-[0.8rem] border border-black bg-white text-black rounded-md "
              >
                Sign Out
              </p>
            </div>
          </div>
        </div>
      </aside>

      <div
        id={`${hamburgerState === false ? "show-hidden-bg" : "hidden-bg"}`}
      ></div>
    </div>
  );
};

export default Sidebar;
